import { Text } from '@shopify/polaris'

type Props = {
  children: JSX.Element | string
}

const HelpText: React.FC<Props> = ({ children }) => {
  return (
    <div className="mt-4 mb-8">
      <Text variant="bodyMd" as="p" fontWeight="regular" tone="subdued">
        {children}
      </Text>
    </div>
  )
}

export { HelpText }
