import { Controller, useFormContext } from 'react-hook-form'

import {
  Layout,
  TextField,
  Text,
  Box,
  InlineStack,
  Button,
  BlockStack,
  Card,
} from '@shopify/polaris'

const MethodTimeSettings = () => {
  const { control, watch, setValue } = useFormContext()

  const timeEnabled = watch('timeEnabled')

  return (
    <Layout.AnnotatedSection
      id="timeSettings"
      title="Time settings"
      description={
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            Time settings give you control over which time slots are able to be
            selected by a customer.
          </Text>
          <Text variant="bodyMd" as="p">
            Use prep time to give yourself enough time to prepare your
            customer&apos;s order.
          </Text>
        </BlockStack>
      }
    >
      <BlockStack gap="400">
        <Card>
          <InlineStack
            gap="200"
            align="space-between"
            blockAlign="center"
            wrap={false}
          >
            <label htmlFor={'bloom-enabled-toggle'}>
              <Text as="p">
                <Text as="span" fontWeight="semibold">
                  Time slots
                </Text>{' '}
                are currently{' '}
                <Text as="span" fontWeight="semibold">
                  {timeEnabled ? 'active' : 'inactive'}
                </Text>
              </Text>
            </label>
            <Box minWidth="fit-content">
              <InlineStack align="end">
                <Button
                  role="switch"
                  id="time-slot-enabled-toggle"
                  ariaChecked={timeEnabled ? 'true' : 'false'}
                  onClick={() => {
                    setValue('timeEnabled', !timeEnabled)
                  }}
                >
                  {timeEnabled ? 'Deactivate' : 'Activate'}
                </Button>
              </InlineStack>
            </Box>
          </InlineStack>
        </Card>
        {timeEnabled && (
          <Card>
            <BlockStack gap="200">
              <Text variant="headingSm" fontWeight="medium" as="h3">
                Time settings
              </Text>
              <Controller
                name="prepTime"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    label="Prep time"
                    helpText="The amount of time you need to prepare an order (in minutes), e.g. 60 for an hour or 2880 for two days."
                    type="number"
                    min={0}
                    value={value?.toString() || ''}
                    onChange={(val) =>
                      onChange(val === '' ? null : parseInt(val, 10))
                    }
                    autoComplete="off"
                  />
                )}
              />
            </BlockStack>
          </Card>
        )}
      </BlockStack>
    </Layout.AnnotatedSection>
  )
}

export { MethodTimeSettings }
