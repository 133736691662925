import { queryOptions } from '@tanstack/react-query'
import { Settings, OrdersResponse, Override } from './types'
import { buildAPIUrl } from './functions'

export const settingsQueryOptions = () =>
  queryOptions({
    queryKey: ['settings'],
    queryFn: async (): Promise<Settings> => {
      const response = await fetch(buildAPIUrl('settings'))
      const data = await response.json()
      return data.message
    },
  })

export const installStatusQueryOptions = () =>
  queryOptions({
    queryKey: ['install-status'],
    queryFn: async (): Promise<{
      status: boolean
      message: {
        host: string
        shopId: string
      }
    }> => {
      const searchParams = new URLSearchParams()
      searchParams.set('redirect', 'false')

      const response = await fetch(buildAPIUrl('auth', searchParams))
      const data = await response.json()
      return data
    },
    refetchOnMount: false,
    refetchOnReconnect: false,
  })

type OverridesQueryProps = {
  uuid?: string
}

export const overridesQueryOptions = (params: OverridesQueryProps = {}) => {
  const searchParams = new URLSearchParams()

  const { uuid } = params

  if (uuid) searchParams.append('uuid', uuid)

  return queryOptions({
    queryKey: ['overrides', searchParams],
    queryFn: async (): Promise<Override[]> => {
      const response = await fetch(buildAPIUrl('overrides', searchParams))
      const data = await response.json()
      return data.message
    },
  })
}

type OrdersQueryProps = {
  from: string
  to: string
  method: string | null
  page: number
}

export const ordersQueryOptions = (params: OrdersQueryProps) => {
  const searchParams = new URLSearchParams()

  const { from, to, method, page } = params

  if (method) searchParams.append('method', method)
  searchParams.append('from', from)
  searchParams.append('to', to)
  searchParams.append('limit', '25')
  searchParams.append('page', page.toString())

  return queryOptions({
    queryKey: ['orders', params],
    queryFn: async (): Promise<OrdersResponse> => {
      const response = await fetch(buildAPIUrl('orders', searchParams))
      const data = await response.json()
      return data.message
    },
  })
}
