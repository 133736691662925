import { Banner, Text } from '@shopify/polaris'
import { Method } from '../../common/types'

type Props = {
  enabled: boolean
  toggleEnabled: () => void
  themeVersion: string
  methods: Partial<Method>[] | undefined
}

const SetupBanner = ({ enabled, methods, toggleEnabled }: Props) => {
  // Is there at least one active method with at least one active day
  const hasEnabledMethodAndDays = methods?.some((method) => {
    return method?.enabled && (method?.enabledDays || []).length > 0
  })

  if (!hasEnabledMethodAndDays) {
    return (
      <Banner
        title="Bloom is not currently showing on your storefront"
        tone="warning"
        action={{
          content: `Configure ${methods?.length ? methods[0].name : ''}`,
          url: `/method/${methods?.length ? methods[0].uuid : ''}`,
        }}
        secondaryAction={{
          content: `Configure ${methods?.length ? methods[1].name : ''}`,
          url: `/method/${methods?.length ? methods[1].uuid : ''}`,
        }}
      >
        <Text variant="bodyMd" as="p">
          Please activate at least one delivery method and ensure you&apos;ve
          configured active days.
        </Text>
      </Banner>
    )
  }

  // If Bloom is just not active, show the activation toggle
  if (!enabled) {
    return (
      <Banner
        title="Bloom is inactive"
        tone="warning"
        action={{
          content: 'Activate Bloom',
          onAction: toggleEnabled,
        }}
      >
        <p>
          While Bloom is inactive, the date picker widget will not appear on
          your storefront.
        </p>
      </Banner>
    )
  }

  // Otherwise, we're active, just show deactivate
  return (
    <Banner
      title="Bloom is active"
      tone="success"
      action={{
        content: 'Deactivate Bloom',
        onAction: toggleEnabled,
      }}
    >
      <p>The Bloom date picker is now visible on your storefront.</p>
    </Banner>
  )
}

export default SetupBanner
