import {
  Banner,
  ChoiceList,
  Layout,
  Link,
  BlockStack,
  Text,
  Card,
  Checkbox,
} from '@shopify/polaris'
import { useIsMutating } from '@tanstack/react-query'
import { Controller, useFormContext } from 'react-hook-form'
import { Disabled } from '../shared/Disabled'

const PositionSettings = () => {
  const { control, watch } = useFormContext()
  const isMutatingSettings = useIsMutating({ mutationKey: ['settings'] })

  const themeVersion = watch('themeVersion')

  return (
    <Layout.AnnotatedSection
      id="themeSettings"
      title="Display settings"
      description={
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            Customise the look and feel of Bloom to match your online store.
          </Text>
          <Text variant="bodyMd" as="p">
            If you&apos;re not able to get Bloom to display how you want it,
            contact support for free customization assistance.
          </Text>
        </BlockStack>
      }
    >
      <Card>
        <Disabled isDisabled={!!isMutatingSettings}>
          <BlockStack gap="400">
            <BlockStack gap="200">
              <Text variant="headingSm" fontWeight="medium" as="h3">
                Where should Bloom appear?
              </Text>
              {themeVersion === 'legacy' ? (
                <>
                  <Controller
                    name="showOnProduct"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label="Automatically on the product page"
                        value={value}
                        onChange={onChange}
                        helpText="We will try to insert Bloom before the add to cart button on the product page."
                      />
                    )}
                  />
                  <Controller
                    name="showOnCart"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Checkbox
                        label="Automatically on the cart page"
                        value={value}
                        onChange={onChange}
                        helpText="We will try to insert Bloom before the checkout button on the cart page."
                      />
                    )}
                  />
                </>
              ) : (
                <Banner tone="info">
                  <p>
                    We detected that you&apos;re using a 2.0 theme. You can add
                    Bloom to your online store via the theme editor. If
                    you&apos;re unsure how to do this, read our{' '}
                    <Link
                      target="_blank"
                      url="https://www.shopside.com.au/bloom-article/using-the-bloom-app-block"
                    >
                      guide
                    </Link>
                    .
                  </p>
                </Banner>
              )}
            </BlockStack>
            <BlockStack gap="200">
              <Text variant="headingSm" fontWeight="medium" as="h3">
                How should the Bloom calendar display?
              </Text>
              <Controller
                name="embedded"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <ChoiceList
                    title=""
                    titleHidden={true}
                    choices={[
                      {
                        label: 'Collapsible calendar',
                        value: 'false',
                        helpText:
                          'Show a text field that opens the calendar when clicked.',
                      },
                      {
                        label: 'Embedded calendar',
                        value: 'true',
                        helpText: 'Embed the calendar directly on the page.',
                      },
                    ]}
                    selected={[value ? 'true' : 'false']}
                    onChange={(values) => onChange(values[0] === 'true')}
                  />
                )}
              />
            </BlockStack>
          </BlockStack>
        </Disabled>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export { PositionSettings }
