import { z } from 'zod'

const schema = z.object({
  dateFormat: z.string(),
  timeFormat: z.string(),
  startDay: z.enum(['SUNDAY', 'MONDAY']),
  requiredType: z.enum(['NONE', 'ADD_TO_CART', 'CHECKOUT']),
})

export { schema }
export type FormSchema = z.infer<typeof schema>
