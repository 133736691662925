import { createFileRoute } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { settingsQueryOptions } from '@/common/queries'
import { SettingsForm } from '@/components/settings/SettingsForm'
import { Layout, SkeletonPage } from '@shopify/polaris'
import { Skeleton } from '@/components/shared/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/settings')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      // Pre-fetch data
      queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  return (
    <PageFrame title="Settings">
      <SettingsForm settings={settings} />
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title="Settings">
      <Layout>
        <Skeleton.AnnotatedSection
          title="Date format settings"
          skeletonSections={[3]}
        >
          Control the date and time format used by Bloom and displayed to
          customers.
        </Skeleton.AnnotatedSection>

        <Skeleton.AnnotatedSection
          title="Validation settings"
          skeletonSections={[2]}
        >
          For deeper customization of Bloom.
        </Skeleton.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
