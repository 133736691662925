import { BEACON_ID } from '@/common/constants'
import { settingsQueryOptions } from '@/common/queries'
import { useQuery } from '@tanstack/react-query'

export const useBeacon = () => {
  const { data: settings } = useQuery(settingsQueryOptions())

  const hasBeacon = window.Beacon?.('info')

  const registerBeacon = async (): Promise<void> => {
    if (hasBeacon) return

    return new Promise((resolve, reject) => {
      try {
        window.Beacon('config', { hideFABOnMobile: true })

        window.Beacon('init', BEACON_ID)

        // Use Beacon's onReady callback to know when initialization is complete
        window.Beacon('on', 'ready', () => {
          resolve()
        })

        // Add a timeout in case the ready event never fires
        const timeout = setTimeout(() => {
          reject(new Error('Beacon initialization timed out'))
        }, 5000)

        // Clear timeout if ready event fires
        window.Beacon('on', 'ready', () => {
          clearTimeout(timeout)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  const populateBeacon = async (): Promise<void> => {
    if (!window.Beacon?.('info')) {
      throw new Error('Beacon must be registered before populating')
    }

    try {
      window.Beacon('identify', {
        'shop-id': shopify.config.shop,
        'shop-timezone': settings?.timezone,
      })
    } catch (error) {
      throw new Error('Failed to populate beacon: ' + error)
    }
  }

  return {
    hasBeacon,
    registerBeacon,
    populateBeacon,
  }
}
