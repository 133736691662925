import { TextField } from '@shopify/polaris'

import prettier from 'prettier/standalone'
import parserCss from 'prettier/parser-postcss'

type Props = {
  value: string
  onUpdate: (css: string) => void
}

const CSSEditor = ({ value = '', onUpdate }: Props) => {
  const formatCss = async () => {
    let formattedCss = value

    try {
      formattedCss = await prettier.format(value, {
        parser: 'css',
        plugins: [parserCss],
        htmlWhitespaceSensitivity: 'ignore',
      })
    } catch (e) {
      console.error(e)
    }

    onUpdate(formattedCss)
  }

  return (
    <TextField
      id="customCss"
      label="Custom styling code (CSS)"
      labelAction={{
        content: 'Format',
        onAction: () => {
          formatCss()
        },
      }}
      value={value}
      onChange={onUpdate}
      multiline={6}
      autoComplete="off"
    />
  )
}

export { CSSEditor }
