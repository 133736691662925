// External Imports
import { FieldErrors, FormProvider } from 'react-hook-form'
import { SaveBar } from '@shopify/app-bridge-react'

// Custom Hooks
import useScrollToError from '@/hooks/useScrollToError'

// Form Imports
import { type FormSchema } from './schema'
import { useMethodForm } from './hooks'

// Component Imports
import { MethodActivateSettings } from '@/components/method/MethodActivateSettings'
import { MethodDateSettings } from '@/components/method/MethodDateSettings'
import { MethodTimeSettings } from '@/components/method/MethodTimeSettings'
import { MethodTimeSlotSettings } from '@/components/method/MethodTimeSlotSettings'

import type { Method } from '@/common/types'

interface MethodFormProps {
  method: Method
}

const MethodForm = ({ method }: MethodFormProps) => {
  const { submit, isPending, form } = useMethodForm(method)

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onFormSubmitValid = (data: FormSchema) => {
    submit(data)
  }

  const onFormSubmitInvalid = (errors: FieldErrors<FormSchema>) => {
    setCanFocus(true)
    console.log(errors)
  }

  return (
    <>
      <SaveBar id="method-save-bar" open={isDirty}>
        <button
          variant="primary"
          onClick={() => handleSubmit(onFormSubmitValid, onFormSubmitInvalid)()}
          disabled={isPending}
          loading={isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={isPending}></button>
      </SaveBar>
      <FormProvider {...form}>
        <MethodActivateSettings name={method.name} />
        <MethodDateSettings />
        <MethodTimeSettings />
        <MethodTimeSlotSettings />
      </FormProvider>
    </>
  )
}

export { MethodForm }
