import {
  Card,
  SkeletonBodyText,
  InlineGrid,
  SkeletonTabs,
  Divider,
  Box,
  Grid,
} from '@shopify/polaris'

const SkeletonTable = ({
  rows = 10,
  narrow = false,
}: {
  rows?: number
  narrow?: boolean
}) => {
  return (
    <Card padding="0">
      <Filters />
      {Array.from({ length: rows }).map((_, index) => (
        <div key={`skeleton-table-${index}`}>
          <Divider />
          {narrow ? <NarrowRow key={index} /> : <Row key={index} />}
        </div>
      ))}
    </Card>
  )
}

const Filters = () => {
  return <SkeletonTabs />
}

const Row = () => (
  <Box padding="400">
    <Grid columns={{ sm: 3 }}>
      <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
        <SkeletonBodyText lines={1} />
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
        <InlineGrid columns={2} gap="400">
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
        </InlineGrid>
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
        <InlineGrid columns={3} gap="400">
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
        </InlineGrid>
      </Grid.Cell>
    </Grid>
  </Box>
)

const NarrowRow = () => (
  <Box padding="400">
    <InlineGrid columns={3} gap="400">
      <SkeletonBodyText lines={1} />
      <SkeletonBodyText lines={1} />
      <SkeletonBodyText lines={1} />
    </InlineGrid>
  </Box>
)

export { SkeletonTable }
