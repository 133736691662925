import { Box, Button, Card, InlineStack, Layout, Text } from '@shopify/polaris'
import { Controller, useFormContext } from 'react-hook-form'

const MethodActivateSettings = ({ name }: { name: string }) => {
  const { control } = useFormContext()

  return (
    <Layout.Section>
      <Card>
        <InlineStack
          gap="200"
          align="space-between"
          blockAlign="center"
          wrap={false}
        >
          <Controller
            name="enabled"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <label htmlFor={'method-enabled-toggle'}>
                  <Text as="p">
                    The{' '}
                    <Text as="span" fontWeight="semibold">
                      {name}
                    </Text>{' '}
                    method is currently{' '}
                    <Text as="span" fontWeight="semibold">
                      {value ? 'active' : 'inactive'}
                    </Text>
                  </Text>
                </label>
                <Box minWidth="fit-content">
                  <InlineStack align="end">
                    <Button
                      role="switch"
                      id="method-enabled-toggle"
                      variant={value ? 'secondary' : 'primary'}
                      ariaChecked={value ? 'true' : 'false'}
                      onClick={() => onChange(!value)}
                    >
                      {value ? 'Deactivate' : 'Activate'}
                    </Button>
                  </InlineStack>
                </Box>
              </>
            )}
          />
        </InlineStack>
      </Card>
    </Layout.Section>
  )
}

export { MethodActivateSettings }
