// types.ts
import { z } from 'zod'

export const schema = z.object({
  overrides: z.array(
    z.object({
      deactivated: z.boolean(),
      prepTime: z.number().nullable(),
      products: z.array(
        z.object({
          id: z.string(),
          title: z.string(),
        })
      ),
    })
  ),
})

export type FormSchema = z.infer<typeof schema>
