// React, Context and Shopify

// Polaris library
import { Layout, Page, PageProps } from '@shopify/polaris'

import { APP_NAME } from '@/common/constants'

type ExtendedPageProps = PageProps & {
  hideActions?: boolean
}

const PageFrame = ({
  title = APP_NAME,
  children,
  ...props
}: //hideActions = false,
ExtendedPageProps) => {
  return (
    <Page title={title} {...props}>
      <Layout>{children}</Layout>
    </Page>
  )
}

export { PageFrame }
