import { useMutation } from '@tanstack/react-query'
import { buildAPIUrl } from '@/common/functions'

interface MutationResponse {
  message: string
  data?: any // TODO: What do we actually get back
}

interface MutationError {
  message: string
  status?: number
}

/**
 * Custom hook for handling app installation through an API
 * @returns Mutation object for handling installation process
 */
export const useMutateInstall = () => {
  const performInstall = async (): Promise<string> => {
    try {
      const token = await shopify.idToken()
      const searchParams = new URLSearchParams()
      searchParams.set('host', shopify.config?.host ?? '')
      searchParams.set('token', token)
      searchParams.set('redirect', 'false')

      const requestURL = buildAPIUrl('auth/install', searchParams)

      const response = await fetch(requestURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        // Special handling for 403 status
        if (response.status === 403) {
          throw {
            message: 'Access denied',
            status: 403,
          }
        }
        throw {
          message: errorData.message || 'Installation failed',
          status: response.status,
        }
      }

      const result: MutationResponse = await response.json()
      return result.message
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred'
      throw {
        message: errorMessage,
        status: (error as MutationError).status,
      }
    }
  }

  return useMutation({
    mutationKey: ['install'],
    mutationFn: performInstall,
    onError: (error: MutationError) => {
      console.error('Installation error:', error)
    },
  })
}
