import { FormLayout, Layout, Card, BlockStack, Text } from '@shopify/polaris'

import { Controller, useFormContext } from 'react-hook-form'
import { useIsMutating } from '@tanstack/react-query'
import { Disabled } from '../shared/Disabled'
import { ColorPicker } from '../shared/ColorPicker'

const DesignSettings = () => {
  const { control } = useFormContext()
  const isMutatingSettings = useIsMutating({ mutationKey: ['settings'] })

  return (
    <Layout.AnnotatedSection
      id="designSettings"
      title="Design settings"
      description="Customize the look and feel of the calendar to match your storefront."
    >
      <Card>
        <Disabled isDisabled={!!isMutatingSettings}>
          <BlockStack gap="400">
            <BlockStack gap="200">
              <Text variant="headingSm" fontWeight="medium" as="h3">
                Accent color
              </Text>
              <FormLayout>
                <FormLayout.Group>
                  <Controller
                    name="primaryColour"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ColorPicker
                        label="Background"
                        placeholder={'#4d4d4d'}
                        color={value}
                        onChange={onChange}
                      />
                    )}
                  />
                  <Controller
                    name="textColour"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ColorPicker
                        label="Text"
                        placeholder={'#ffffff'}
                        color={value}
                        onChange={onChange}
                      />
                    )}
                  />
                </FormLayout.Group>
              </FormLayout>
            </BlockStack>
          </BlockStack>
        </Disabled>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export { DesignSettings }
