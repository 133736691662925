//import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { App } from './App'

const container = document.getElementById('root')

if (!container) throw new Error('Failed to find the root element')

// Disable logs on production
if (import.meta.env.NODE_ENV === 'production') {
  const emptyFunction = () => {} // eslint-disable-line
  console.log = emptyFunction
  console.info = emptyFunction
  console.error = emptyFunction
  console.debug = emptyFunction
}

const root = createRoot(container)

root.render(<App />)
