import {
  createRootRouteWithContext,
  Outlet,
  ScrollRestoration,
  Link,
} from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import { FooterHelp, Frame } from '@shopify/polaris'
import { useSuspenseQuery, type QueryClient } from '@tanstack/react-query'
import { NavMenu } from '@shopify/app-bridge-react'
import { settingsQueryOptions } from '@/common/queries'
import { useBeacon } from '@/hooks/useBeacon'
import { useEffect } from 'react'

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: Component,
})

function Component() {
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  const { registerBeacon, populateBeacon } = useBeacon()

  useEffect(() => {
    const initBeacon = async () => {
      try {
        await registerBeacon()
        await populateBeacon()
      } catch (error) {
        console.error('Failed to initialize beacon:', error)
      }
    }

    initBeacon()
  }, [])

  return (
    <Frame>
      <NavMenu>
        <Link to="/" rel="home">
          Dashboard
        </Link>
        <Link to="/orders">Upcoming Orders</Link>
        {settings.methods.map((method) => (
          <Link key={method.uuid} to={`/method/${method.uuid}`}>
            {method.name}
          </Link>
        ))}
        <Link to="/overrides">Overrides</Link>
        <Link to="/settings">Settings</Link>
        <Link to="/display">Display</Link>
        <Link to="/localization">Localization</Link>
      </NavMenu>
      <ScrollRestoration />
      <Outlet />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
      <div className="pt-12 pb-16">
        <FooterHelp>
          Proudly made and supported by{' '}
          <a target="_blank" href="https://www.shopside.com.au">
            Shopside
          </a>
          .
        </FooterHelp>
      </div>
    </Frame>
  )
}
