import { createFileRoute, useParams } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { settingsQueryOptions } from '@/common/queries'
import { useSuspenseQuery } from '@tanstack/react-query'
import { BlockStack, Layout, SkeletonPage, Text } from '@shopify/polaris'
import { MethodForm } from '@/components/method/MethodForm'
import { Skeleton } from '@/components/shared/Skeleton'

export const Route = createFileRoute('/method/$uuid/')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { uuid } = useParams({ strict: false }) as { uuid: string }
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const method = settings.methods.find((method) => method.uuid === uuid)

  if (!method) {
    return null
  }

  const { name } = method

  return (
    <PageFrame title={`${name} Settings`}>
      <MethodForm key={uuid} method={method} />
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title="Method settings" primaryAction>
      <Layout>
        <Skeleton.AnnotatedSection title="Date settings" skeletonSections={[3]}>
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Select which days you would like to activate. These will be
              available for customers to select.
            </Text>
            <Text variant="bodyMd" as="p">
              Block out specific dates you don&apos;t want customers to be able
              to choose.
            </Text>
            <Text variant="bodyMd" as="p">
              Use the minimum and maximum cut-off days to limit the date range
              from which your customers can select.
            </Text>
            <Text variant="bodyMd" as="p">
              Set a cut-off time for same-day or next-day orders, to ensure you
              have enough time to prepare their order.
            </Text>
          </BlockStack>
        </Skeleton.AnnotatedSection>

        <Skeleton.AnnotatedSection title="Time settings" skeletonSections={[2]}>
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Time settings give you control over which time slots are able to
              be selected by a customer.
            </Text>
            <Text variant="bodyMd" as="p">
              Use prep time to give yourself enough time to prepare your
              customer&apos;s order.
            </Text>
          </BlockStack>
        </Skeleton.AnnotatedSection>

        <Skeleton.AnnotatedSection title="Time slots" skeletonSections={[3]}>
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Time slots allow you to create time slot groups and assign them to
              specific days.
            </Text>
            <Text variant="bodyMd" as="p">
              You can add individual time slots to your group, or use the time
              slot generator to create them in bulk.
            </Text>
            <Text variant="bodyMd" as="p">
              You can delete a time group by clearing all existing time slots
              using the text button at the bottom of the group. Once the times
              have been removed, the option to delete the group will appear.
            </Text>
          </BlockStack>
        </Skeleton.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
