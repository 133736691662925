import {
  BlockStack,
  Button,
  Card,
  ChoiceList,
  Divider,
  InlineStack,
  Layout,
  Text,
  TextField,
} from '@shopify/polaris'

import { ProductList } from '@/components/shared/ProductList'
import {
  Controller,
  UseFieldArrayReturn,
  useFormContext,
} from 'react-hook-form'
import { OVERRIDE_TEMPLATE } from '@/common/constants'
import { Override } from '@/common/types'

const OverrideGroups = ({
  fieldArray,
}: {
  fieldArray: UseFieldArrayReturn<{ overrides: Override[] }, 'overrides', 'id'>
}) => {
  const { control } = useFormContext()

  const { fields, append, remove } = fieldArray

  const addOverride = () => {
    append(OVERRIDE_TEMPLATE)
  }

  const removeOverride = (index: number) => {
    remove(index)
  }

  return (
    <>
      <Layout.AnnotatedSection
        id="overrides"
        title="Override groups"
        description={
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Override groups allow you to override specific settings for a
              group of products.
            </Text>

            <Text variant="bodyMd" as="p">
              You can delete an override group by removing all existing products
              using the text button at the bottom of the group. Once the
              products have been removed, the option to delete the group will
              appear.
            </Text>
          </BlockStack>
        }
      >
        <BlockStack gap="400">
          {fields.map((field, index) => (
            <BlockStack key={field.id} gap="400">
              <Card>
                <BlockStack gap="400">
                  <BlockStack gap="200">
                    <Text variant="headingSm" fontWeight="medium" as="h3">
                      Visibility
                    </Text>
                    <Controller
                      name={`overrides.${index}.deactivated`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <ChoiceList
                          title={'Hide for these products?'}
                          choices={[
                            {
                              label: 'No',
                              value: 'false',
                              helpText:
                                'Bloom will show as normal for these products',
                            },
                            {
                              label: 'Yes',
                              value: 'true',
                              helpText:
                                'Bloom will be hidden if any of these products are added to the cart',
                            },
                          ]}
                          selected={[value ? 'true' : 'false']}
                          onChange={(values) => onChange(values[0] === 'true')}
                        />
                      )}
                    />
                  </BlockStack>
                  <BlockStack gap="200">
                    <Text variant="headingSm" fontWeight="medium" as="h3">
                      Time overrides
                    </Text>
                    <Controller
                      name={`overrides.${index}.prepTime`}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Prep time"
                          helpText="The amount of time you need to prepare an order (in minutes), e.g. 60 for an hour or 2880 for two days. Leave this blank if you don't want to alter the prep time."
                          type="number"
                          min={0}
                          value={value?.toString()}
                          onChange={(val) => {
                            onChange(parseInt(val, 10))
                          }}
                          autoComplete="off"
                        />
                      )}
                    />
                  </BlockStack>
                  <Divider />
                  <Controller
                    name={`overrides.${index}.products`}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <ProductList
                        products={value || []}
                        updateProducts={(products) => {
                          onChange(products)
                        }}
                        deleteButton={() => <></>}
                      />
                    )}
                  />
                  <InlineStack align="start">
                    <Button
                      onClick={async () => {
                        removeOverride(index)
                      }}
                    >
                      Delete override group
                    </Button>
                  </InlineStack>
                </BlockStack>
              </Card>
            </BlockStack>
          ))}
        </BlockStack>
      </Layout.AnnotatedSection>
      <Layout.Section>
        <InlineStack align="end">
          <Button onClick={addOverride}>Add another override group</Button>
        </InlineStack>
      </Layout.Section>
    </>
  )
}

export { OverrideGroups }
