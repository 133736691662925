import { Select } from '@shopify/polaris'
import { Time } from '../../common/types'

import { TIME_SELECT_OPTIONS } from '../../common/constants'
import { minutesToTime } from '../../common/functions'

type Props = {
  label: string
  stringKey: keyof Pick<Time, 'from' | 'to'>
  value: string
  onChange: (
    stringKey: keyof Pick<Time, 'from' | 'to'>,
    value: {
      minutes: number
      time: string
    }
  ) => void
}

const TimeSelect: React.FC<Props> = ({
  label,
  stringKey,
  value = '',
  onChange,
}) => {
  return (
    <Select
      label={label}
      options={TIME_SELECT_OPTIONS}
      onChange={(val) => {
        const minutes = parseInt(val)
        onChange(stringKey, {
          minutes,
          time: minutesToTime(minutes),
        })
      }}
      value={value}
    />
  )
}

export { TimeSelect }
