import { BlockStack, Card, Layout, Select } from '@shopify/polaris'

import { Controller, useFormContext } from 'react-hook-form'
import { useIsMutating } from '@tanstack/react-query'
import { Disabled } from '@/components/shared/Disabled'

const dateFormatOptions = [
  { value: 'YYYY-MM-DD', label: '2022-01-31' },
  { value: 'MM/DD/YYYY', label: '01/31/2022' },
  { value: 'DD/MM/YYYY', label: '31/01/2022' },
  { value: 'D MMMM YYYY', label: '31 January 2022' },
  { value: 'MMM D, YYYY', label: 'Jan 31, 2022' },
  { value: 'MMMM D, YYYY', label: 'January 31, 2022' },
]

const timeFormatOptions = [
  { value: 'h:mm a', label: '8:00 am - 2:00 pm' },
  { value: 'hh:mm a', label: '08:00 am - 02:00 pm' },
  { value: 'HH:mm', label: '08:00 - 14:00' },
]

const startDayOptions = [
  { label: 'Sunday', value: 'SUNDAY' },
  { label: 'Monday', value: 'MONDAY' },
]

const DateFormatSettings = () => {
  const { control } = useFormContext()

  const isMutatingSettings = useIsMutating({ mutationKey: ['settings'] })

  return (
    <Layout.AnnotatedSection
      id="dateFormatSettings"
      title="Date format settings"
      description="Control the date and time format used by Bloom and displayed to customers."
    >
      <Card>
        <Disabled isDisabled={!!isMutatingSettings}>
          <BlockStack gap="400">
            <Controller
              name="dateFormat"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  label="Date format"
                  helpText="The format of the dates displayed by Bloom."
                  options={dateFormatOptions}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="timeFormat"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  label="Time format"
                  helpText="The format of the dates displayed by Bloom."
                  options={timeFormatOptions}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                />
              )}
            />

            <Controller
              name="startDay"
              control={control}
              render={({
                field: { onChange, onBlur, value },
                fieldState: { error },
              }) => (
                <Select
                  label="First day of the week"
                  helpText="The day that appears in the first column of the calendar."
                  options={startDayOptions}
                  value={value}
                  onChange={onChange}
                  onBlur={onBlur}
                  error={error?.message}
                />
              )}
            />
          </BlockStack>
        </Disabled>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export { DateFormatSettings }
