import { Layout, TextField, BlockStack, Text, Card } from '@shopify/polaris'

import { CSSEditor } from '@/components/shared/CSSEditor'
import { useIsMutating } from '@tanstack/react-query'
import { Controller, useFormContext } from 'react-hook-form'
import { Disabled } from '../shared/Disabled'

const AdvancedSettings = () => {
  const { control, watch } = useFormContext()
  const isMutatingSettings = useIsMutating({ mutationKey: ['settings'] })

  const themeVersion = watch('themeVersion')

  return (
    <Layout.AnnotatedSection
      id="advancedDisplaySettings"
      title="Advanced display settings"
      description={
        <BlockStack gap="200">
          <Text variant="bodyMd" as="p">
            We may change these settings for you if you have advanced display
            requirements.
          </Text>
          <Text variant="bodyMd" as="p">
            If you&apos;re unsure what these settings do, it&apos;s best to
            leave them as they are.
          </Text>
        </BlockStack>
      }
    >
      <Card>
        <Disabled isDisabled={!!isMutatingSettings}>
          <BlockStack gap="400">
            {themeVersion === 'legacy' && (
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="medium" as="h3">
                  Custom placement
                </Text>
                <Controller
                  name="selector"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="selector"
                      label="Element selector"
                      placeholder="e.g. form[action=/cart], .product-price"
                      helpText="The date picker will appear before your selected elements (comma separated)"
                      value={value}
                      onChange={(val) => {
                        onChange(val !== '' ? val : null)
                      }}
                      autoComplete="off"
                    />
                  )}
                />
              </BlockStack>
            )}

            <BlockStack gap="200">
              <Text variant="headingSm" fontWeight="medium" as="h3">
                Custom styling
              </Text>
              <Controller
                name="customCss"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CSSEditor value={value} onUpdate={onChange} />
                )}
              />
            </BlockStack>
          </BlockStack>
        </Disabled>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export { AdvancedSettings }
