import { StringMapType } from './types'

export const APP_NAME = 'Bloom'
export const BEACON_ID = '3c0a559c-aaff-43e2-be44-e87e2955e9f9'

export const SHORT_DURATION = 1000
export const MEDIUM_DURATION = 3000
export const LONG_DURATION = 5000

export const DAYS = [
  {
    label: 'Mon',
    value: 1,
  },
  {
    label: 'Tues',
    value: 2,
  },
  {
    label: 'Wed',
    value: 3,
  },
  {
    label: 'Thur',
    value: 4,
  },
  {
    label: 'Fri',
    value: 5,
  },
  {
    label: 'Sat',
    value: 6,
  },
  {
    label: 'Sun',
    value: 0,
  },
]

export const TIME_SELECT_OPTIONS = [
  { value: '0', label: '12:00 am' },
  { value: '15', label: '12:15 am' },
  { value: '30', label: '12:30 am' },
  { value: '45', label: '12:45 am' },
  { value: '60', label: '1:00 am' },
  { value: '75', label: '1:15 am' },
  { value: '90', label: '1:30 am' },
  { value: '105', label: '1:45 am' },
  { value: '120', label: '2:00 am' },
  { value: '135', label: '2:15 am' },
  { value: '150', label: '2:30 am' },
  { value: '165', label: '2:45 am' },
  { value: '180', label: '3:00 am' },
  { value: '195', label: '3:15 am' },
  { value: '210', label: '3:30 am' },
  { value: '225', label: '3:45 am' },
  { value: '240', label: '4:00 am' },
  { value: '255', label: '4:15 am' },
  { value: '270', label: '4:30 am' },
  { value: '285', label: '4:45 am' },
  { value: '300', label: '5:00 am' },
  { value: '315', label: '5:15 am' },
  { value: '330', label: '5:30 am' },
  { value: '345', label: '5:45 am' },
  { value: '360', label: '6:00 am' },
  { value: '375', label: '6:15 am' },
  { value: '390', label: '6:30 am' },
  { value: '405', label: '6:45 am' },
  { value: '420', label: '7:00 am' },
  { value: '435', label: '7:15 am' },
  { value: '450', label: '7:30 am' },
  { value: '465', label: '7:45 am' },
  { value: '480', label: '8:00 am' },
  { value: '495', label: '8:15 am' },
  { value: '510', label: '8:30 am' },
  { value: '525', label: '8:45 am' },
  { value: '540', label: '9:00 am' },
  { value: '555', label: '9:15 am' },
  { value: '570', label: '9:30 am' },
  { value: '585', label: '9:45 am' },
  { value: '600', label: '10:00 am' },
  { value: '615', label: '10:15 am' },
  { value: '630', label: '10:30 am' },
  { value: '645', label: '10:45 am' },
  { value: '660', label: '11:00 am' },
  { value: '675', label: '11:15 am' },
  { value: '690', label: '11:30 am' },
  { value: '705', label: '11:45 am' },
  { value: '720', label: '12:00 pm' },
  { value: '735', label: '12:15 pm' },
  { value: '750', label: '12:30 pm' },
  { value: '765', label: '12:45 pm' },
  { value: '780', label: '1:00 pm' },
  { value: '795', label: '1:15 pm' },
  { value: '810', label: '1:30 pm' },
  { value: '825', label: '1:45 pm' },
  { value: '840', label: '2:00 pm' },
  { value: '855', label: '2:15 pm' },
  { value: '870', label: '2:30 pm' },
  { value: '885', label: '2:45 pm' },
  { value: '900', label: '3:00 pm' },
  { value: '915', label: '3:15 pm' },
  { value: '930', label: '3:30 pm' },
  { value: '945', label: '3:45 pm' },
  { value: '960', label: '4:00 pm' },
  { value: '975', label: '4:15 pm' },
  { value: '990', label: '4:30 pm' },
  { value: '1005', label: '4:45 pm' },
  { value: '1020', label: '5:00 pm' },
  { value: '1035', label: '5:15 pm' },
  { value: '1050', label: '5:30 pm' },
  { value: '1065', label: '5:45 pm' },
  { value: '1080', label: '6:00 pm' },
  { value: '1095', label: '6:15 pm' },
  { value: '1110', label: '6:30 pm' },
  { value: '1125', label: '6:45 pm' },
  { value: '1140', label: '7:00 pm' },
  { value: '1155', label: '7:15 pm' },
  { value: '1170', label: '7:30 pm' },
  { value: '1185', label: '7:45 pm' },
  { value: '1200', label: '8:00 pm' },
  { value: '1215', label: '8:15 pm' },
  { value: '1230', label: '8:30 pm' },
  { value: '1245', label: '8:45 pm' },
  { value: '1260', label: '9:00 pm' },
  { value: '1275', label: '9:15 pm' },
  { value: '1290', label: '9:30 pm' },
  { value: '1305', label: '9:45 pm' },
  { value: '1320', label: '10:00 pm' },
  { value: '1335', label: '10:15 pm' },
  { value: '1350', label: '10:30 pm' },
  { value: '1365', label: '10:45 pm' },
  { value: '1380', label: '11:00 pm' },
  { value: '1395', label: '11:15 pm' },
  { value: '1410', label: '11:30 pm' },
  { value: '1425', label: '11:45 pm' },
  { value: '1440', label: '12:00 am' },
]

export const OVERRIDE_TEMPLATE = {
  deactivated: false,
  prepTime: null,
  products: [],
}

export const TIME_SLOT_TEMPLATE = {
  from: {
    minutes: 540,
    time: '9:00 am',
  },
  to: {
    minutes: 720,
    time: '12:00 pm',
  },
  limit: null,
}

export const TIME_GROUP_TEMPLATE = {
  allowSimultaneous: false,
  days: [],
  times: [],
}

export const STRING_MAP = {
  fieldLabels: {
    deliveryMethod: {
      label: 'Delivery method',
      placeholder: 'Show dates/times for',
      helpText:
        'The label displayed above the dropdown field when you have multiple delivery methods.',
    },
    deliveryDate: {
      label: 'Delivery date',
      placeholder: 'Choose a date',
      helpText: 'The label displayed above the calendar text field.',
    },
    deliveryTime: {
      label: 'Delivery time',
      placeholder: 'Choose a time',
      helpText: 'The label displayed above the timeslot dropdown field.',
    },
  },
  messages: {
    methodMissing: {
      label: 'Method missing',
      placeholder: 'Please select a delivery method',
      helpText:
        'The error message shown if the customer hasn’t selected a delivery method and you’ve activated the “required” validation setting.',
    },
    dateMissing: {
      label: 'Data missing',
      placeholder: 'Please select a date',
      helpText:
        'The error message shown if the customer hasn’t selected a date and you’ve activated the “required” validation setting.',
    },
    timeMissing: {
      label: 'Time missing',
      placeholder: 'Please select a time',
      helpText:
        'The error message shown if the customer hasn’t selected a time and you’ve activated the “required” validation setting.',
    },
    noTimes: {
      label: 'No times',
      placeholder: 'No times available for this date',
      helpText:
        'The message shown inside the timeslot dropdown if no valid times are returned for the selected date.',
    },
    chooseTime: {
      label: 'Choose time',
      placeholder: '-- Please choose a time --',
      helpText:
        'The message shown inside the timeslot dropdown once new timeslots are loaded in but none have been selected.',
    },
    soldOut: {
      label: 'Sold out',
      placeholder: '(Sold Out)',
      helpText:
        'This text will be added to the end of the timeslot if the order limit has been reached.',
    },
  },
  monthNames: {
    0: {
      label: 'January',
      placeholder: 'January',
    },
    1: {
      label: 'February',
      placeholder: 'February',
    },
    2: {
      label: 'March',
      placeholder: 'March',
    },
    3: {
      label: 'April',
      placeholder: 'April',
    },
    4: {
      label: 'May',
      placeholder: 'May',
    },
    5: {
      label: 'June',
      placeholder: 'June',
    },
    6: {
      label: 'July',
      placeholder: 'July',
    },
    7: {
      label: 'August',
      placeholder: 'August',
    },
    8: {
      label: 'September',
      placeholder: 'September',
    },
    9: {
      label: 'October',
      placeholder: 'October',
    },
    10: {
      label: 'November',
      placeholder: 'November',
    },
    11: {
      label: 'December',
      placeholder: 'December',
    },
  },
  dayNamesShort: {
    0: {
      label: 'Sunday',
      placeholder: 'S',
    },
    1: {
      label: 'Monday',
      placeholder: 'M',
    },
    2: {
      label: 'Tuesday',
      placeholder: 'T',
    },
    3: {
      label: 'Wednesday',
      placeholder: 'W',
    },
    4: {
      label: 'Thursday',
      placeholder: 'T',
    },
    5: {
      label: 'Friday',
      placeholder: 'F',
    },
    6: {
      label: 'Saturday',
      placeholder: 'S',
    },
  },
  tags: {
    date: {
      label: 'Date',
      placeholder: 'Delivery Date',
      helpText:
        'The prefix used for the selected date Tag, e.g. "Delivery Date" = Delivery Date: 01/01/2023. Leave blank to show no prefix and just the selected value.',
    },
    time: {
      label: 'Time',
      placeholder: 'Delivery Time',
      helpText:
        'The prefix used for the selected time Tag, e.g. "Delivery Time" = Delivery Time: 9:00am - 10:00am. Leave blank to show no prefix and just the selected value.',
    },
    method: {
      label: 'Method',
      placeholder: 'Delivery Method',
      helpText:
        'The prefix used for the selected method Tag, e.g. "Delivery Method" = Delivery Method: Pick Up. Leave blank to show no prefix and just the selected value.',
    },
  },
} as StringMapType
