import { createFileRoute } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { settingsQueryOptions } from '@/common/queries'

import { LocalizationForm } from '@/components/localization/LocalizationForm'
import { BlockStack, Layout, SkeletonPage, Text } from '@shopify/polaris'
import { Skeleton } from '@/components/shared/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/localization')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      // Pre-fetch data
      queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  return (
    <PageFrame title="Localization">
      <LocalizationForm settings={settings} />
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title="Localization">
      <Layout>
        <Skeleton.AnnotatedSection
          title="Localization settings"
          skeletonSections={[3, 3, 3]}
        >
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Use these settings to modify all labels and messaging that your
              customer sees.
            </Text>
            <Text variant="bodyMd" as="p">
              You can use this to translate Bloom into your desired language.
            </Text>
          </BlockStack>
        </Skeleton.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
