import type { FormSchema } from './schema'

export const sanitizeMethodData = (data: FormSchema): FormSchema => {
  return {
    uuid: data.uuid ?? '',
    shopId: data.shopId ?? '',
    enabled: data.enabled ?? true,
    name: data.name ?? '',
    isDefault: data.isDefault ?? false,
    enabledDays: data.enabledDays ?? [],
    disabledDates: data.disabledDates ?? [],
    minDaysInAdvance: data.minDaysInAdvance ?? null,
    maxDaysInAdvance: data.maxDaysInAdvance ?? null,
    timeEnabled: data.timeEnabled ?? false,
    timeSlots: data.timeSlots ?? [],
    prepTime: data.prepTime ?? null,
    cutOffTime: data.cutOffTime ?? null,
    createdAt: data.createdAt ?? '',
    navIcon: data.navIcon ?? '',
    slug: data.slug ?? '',
    cutOffTimeType: data.cutOffTimeType ?? 'SAME_DAY',
  }
}
