import { useMutation, useQueryClient } from '@tanstack/react-query'
import { buildAPIUrl } from '@/common/functions'
import { settingsQueryOptions } from '@/common/queries'
import { Settings } from '@/common/types'

interface MutationResponse {
  message: string
  data?: Settings
}

interface MutationError {
  message: string
  status?: number
}

/**
 * Custom hook for mutating Settings through an API
 * @returns Mutation object for handling Settings updates
 */
const useMutateSettings = () => {
  const queryClient = useQueryClient()
  const requestURL = buildAPIUrl('settings')

  const updateSettings = async (
    data: Partial<Settings> = {}
  ): Promise<string> => {
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          settings: {
            ...data,
            shopId: shopify.config.shop,
          },
        }),
      })

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        throw {
          message: errorData.message || 'Failed to update settings',
          status: response.status,
        }
      }

      const result: MutationResponse = await response.json()
      return result.message
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred'
      throw {
        message: errorMessage,
        status: (error as MutationError).status,
      }
    }
  }

  return useMutation({
    mutationKey: ['settings'],
    mutationFn: updateSettings,
    onSuccess: (message) => {
      console.log('Settings updated successfully:', message)
      queryClient.invalidateQueries({
        queryKey: settingsQueryOptions().queryKey,
      })
      return true
    },
    onError: (error: MutationError) => {
      console.error('Settings mutation error:', error)

      shopify.toast.show(error.message || 'Failed to update settings', {
        isError: true,
      })
    },
  })
}

export default useMutateSettings
