import { createFileRoute } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { settingsQueryOptions } from '@/common/queries'
import { DisplayForm } from '@/components/display/DisplayForm'
import { BlockStack, Layout, SkeletonPage, Text } from '@shopify/polaris'
import { Skeleton } from '@/components/shared/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/display')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  return (
    <PageFrame title="Display">
      <DisplayForm settings={settings} />
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title="Display">
      <Layout>
        <Skeleton.AnnotatedSection
          title="Display settings"
          skeletonSections={[2]}
        >
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Customise the look and feel of Bloom to match your online store.
            </Text>
            <Text variant="bodyMd" as="p">
              If you&apos;re not able to get Bloom to display how you want it,
              contact support for free customization assistance.
            </Text>
          </BlockStack>
        </Skeleton.AnnotatedSection>
        <Skeleton.AnnotatedSection
          title="Display settings"
          skeletonSections={[1]}
        >
          Customize the look and feel of the calendar to match your storefront.
        </Skeleton.AnnotatedSection>
        <Skeleton.AnnotatedSection
          title="Display settings"
          skeletonSections={[1]}
        >
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              We may change these settings for you if you have advanced display
              requirements.
            </Text>
            <Text variant="bodyMd" as="p">
              If you&apos;re unsure what these settings do, it&apos;s best to
              leave them as they are.
            </Text>
          </BlockStack>
        </Skeleton.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
