import { useMutation, useQueryClient } from '@tanstack/react-query'
import { buildAPIUrl } from '@/common/functions'
import { overridesQueryOptions } from '@/common/queries'
import { Override } from '@/common/types'

interface MutationResponse {
  message: string
  data?: Override[]
}

interface MutationError {
  message: string
  status?: number
}

/**
 * Custom hook for mutating Override entities through an API
 * @returns Mutation object for handling Override updates
 */
const useMutateOverrides = () => {
  const queryClient = useQueryClient()
  const requestURL = buildAPIUrl('overrides')

  const updateOverrides = async (data: Override[]): Promise<string> => {
    try {
      const response = await fetch(requestURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ overrides: data }),
      })

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}))
        throw {
          message: errorData.message || 'Failed to update overrides',
          status: response.status,
        }
      }

      const result: MutationResponse = await response.json()
      return result.message
    } catch (error) {
      const errorMessage =
        error instanceof Error ? error.message : 'An unexpected error occurred'
      throw {
        message: errorMessage,
        status: (error as MutationError).status,
      }
    }
  }

  return useMutation({
    mutationKey: ['mutateOverrides'],
    mutationFn: updateOverrides,
    onSuccess: (message) => {
      queryClient.invalidateQueries({
        queryKey: overridesQueryOptions().queryKey,
      })

      // Show success message
      shopify.toast.show(message || 'Overrides updated successfully')
    },
    onError: (error: MutationError) => {
      console.error('Override mutation error:', error)

      shopify.toast.show(error.message || 'Failed to update overrides', {
        isError: true,
      })
    },
  })
}

export default useMutateOverrides
