import {
  Banner,
  ChoiceList,
  Layout,
  Link,
  BlockStack,
  Card,
  Text,
} from '@shopify/polaris'

import { Controller, useFormContext } from 'react-hook-form'
import { useIsMutating } from '@tanstack/react-query'
import { Disabled } from '@/components/shared/Disabled'

const ValidationSettings = () => {
  const { control, watch } = useFormContext()
  const isMutatingSettings = useIsMutating({ mutationKey: ['settings'] })

  const requiredType = watch('requiredType')

  return (
    <Layout.AnnotatedSection
      id="validationSettings"
      title="Validation settings"
      description="For deeper customization of Bloom."
    >
      <Card>
        <Disabled isDisabled={!!isMutatingSettings}>
          <BlockStack gap="400">
            <Controller
              name="requiredType"
              control={control}
              render={({ field: { onChange, value } }) => (
                <BlockStack gap="100">
                  <Text variant="headingSm" fontWeight="medium" as="h3">
                    Are customers required to select a date?
                  </Text>
                  <ChoiceList
                    titleHidden={true}
                    title="Are customers required to select a date?"
                    choices={[
                      {
                        label: 'No, selecting a date is optional',
                        value: 'NONE',
                        helpText:
                          "The calendar will still appear, but it won't prevent the customer from checking out.",
                      },
                      {
                        label: 'Yes, a date is required before checkout',
                        value: 'CHECKOUT',
                        helpText:
                          "Customers can still add to cart, but won't be able to checkout until a date is chosen (cart page only).",
                      },
                      {
                        label: 'Yes, a date is required before adding to cart',
                        value: 'ADD_TO_CART',
                        helpText:
                          'Add to cart buttons will be deactivated until a date is chosen (product page only).',
                      },
                    ]}
                    onChange={(values) => onChange(values[0])}
                    selected={[value]}
                  />
                </BlockStack>
              )}
            />

            {requiredType !== 'NONE' && (
              <BlockStack gap="200">
                <Banner tone="info">
                  <p>
                    This setting doesn&apos;t apply to "Buy it now" buttons.
                    Shopify explain why in their{' '}
                    <Link
                      target="_blank"
                      url="https://help.shopify.com/en/manual/online-store/dynamic-checkout/compatibility"
                    >
                      documentation
                    </Link>
                    .
                  </p>
                </Banner>
              </BlockStack>
            )}
          </BlockStack>
        </Disabled>
      </Card>
    </Layout.AnnotatedSection>
  )
}

export { ValidationSettings }
