import { createFileRoute } from '@tanstack/react-router'

import { BlockStack, Layout, Link, Text } from '@shopify/polaris'
import { PageFrame } from '@/components/shared/PageFrame'
import { settingsQueryOptions } from '@/common/queries'
import { OrdersTable } from '@/components/orders/OrdersTable'
import { Skeleton } from '@/components/shared/Skeleton'

export const Route = createFileRoute('/orders')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      // Pre-fetch data
      queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  return (
    <PageFrame title="Upcoming orders">
      <Layout.Section>
        <Text as="p" variant="bodyMd">
          This table will only show orders that have a{' '}
          <strong>date or time attached.</strong> To view orders without a date,
          use the <Link url="shopify:admin/orders/">Shopify orders table</Link>.
        </Text>
      </Layout.Section>
      <OrdersTable />
    </PageFrame>
  )
}

function Pending() {
  return (
    <PageFrame title="Upcoming orders">
      <Layout.Section>
        <Text as="p" variant="bodyMd">
          This table will only show orders that have a{' '}
          <strong>date or time attached.</strong> To view orders without a date,
          use the <Link url="shopify:admin/orders/">Shopify orders table</Link>.
        </Text>
      </Layout.Section>
      <Layout.Section>
        <BlockStack gap="400">
          <Skeleton.Table />
        </BlockStack>
      </Layout.Section>
    </PageFrame>
  )
}
