import type { FormSchema } from './schema'
import { zodResolver } from '@hookform/resolvers/zod'
import { ZodSchema } from 'zod'

export default (schema: ZodSchema<FormSchema>) => {
  return zodResolver(schema)
  // schema.refine(
  //   (data) => {
  //     if (data.minDaysInAdvance && data.maxDaysInAdvance) {
  //       return data.minDaysInAdvance <= data.maxDaysInAdvance
  //     }
  //     return true
  //   },
  //   {
  //     // Custom error message
  //     message:
  //       'Min days in advance must be less than or equal to max days in advance',
  //     path: ['minDaysInAdvance'],
  //   }
  // )
}
