import { z } from 'zod'

const schema = z.object({
  showOnProduct: z.boolean(),
  showOnCart: z.boolean(),
  embedded: z.boolean(),
  primaryColour: z.string().nullable(),
  textColour: z.string().nullable(),
  selector: z.string().nullable(),
  customCss: z.string().nullable(),
})

export { schema }
export type FormSchema = z.infer<typeof schema>
