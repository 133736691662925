// External Imports
import { Controller, FieldErrors, FormProvider } from 'react-hook-form'
import { SaveBar } from '@shopify/app-bridge-react'

import {
  BlockStack,
  Card,
  FormLayout,
  Layout,
  Text,
  TextField,
} from '@shopify/polaris'

// Custom Hooks
import useScrollToError from '@/hooks/useScrollToError'

// Form Imports
import { type FormSchema } from './schema'
import { useLocalizationForm } from './hooks'

import { Settings } from '@/common/types'

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

interface LocalizationFormProps {
  settings: Settings
}

const LocalizationForm = ({ settings }: LocalizationFormProps) => {
  const { submit, isPending, form } = useLocalizationForm(settings)

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
    control,
  } = form

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onFormSubmitValid = (data: FormSchema) => {
    submit(data)
  }

  const onFormSubmitInvalid = (errors: FieldErrors<FormSchema>) => {
    setCanFocus(true)
    console.log(errors)
  }

  return (
    <>
      <SaveBar id="localization-save-bar" open={isDirty}>
        <button
          variant="primary"
          onClick={() => handleSubmit(onFormSubmitValid, onFormSubmitInvalid)()}
          disabled={isPending}
          loading={isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={isPending}></button>
      </SaveBar>
      <FormProvider {...form}>
        <Layout.AnnotatedSection
          id="localization"
          title="Localization"
          description={
            <BlockStack gap="200">
              <Text variant="bodyMd" as="p">
                Use these settings to modify all labels and messaging that your
                customer sees.
              </Text>
              <Text variant="bodyMd" as="p">
                You can use this to translate Bloom into your desired language.
              </Text>
            </BlockStack>
          }
        >
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <Text variant="headingMd" fontWeight="medium" as="h2">
                  Field Labels
                </Text>
                <FormLayout>
                  <Controller
                    name="fieldLabels.deliveryMethod"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Delivery method"
                        placeholder="Show dates/times for"
                        helpText="The label displayed above the dropdown field when you have multiple delivery methods."
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="fieldLabels.deliveryDate"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Delivery date"
                        placeholder="Choose a date"
                        helpText="The label displayed above the calendar text field."
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="fieldLabels.deliveryTime"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Delivery time"
                        placeholder="Choose a time"
                        helpText="The label displayed above the timeslot dropdown field."
                        {...field}
                      />
                    )}
                  />
                </FormLayout>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <Text variant="headingMd" fontWeight="medium" as="h2">
                  Messages
                </Text>
                <FormLayout>
                  <Controller
                    name="messages.methodMissing"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Method missing"
                        placeholder="Please select a delivery method"
                        helpText={`The error message shown if the customer hasn't selected a delivery method and you've activated the "required" validation setting.`}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="messages.dateMissing"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Date missing"
                        placeholder="Please select a date"
                        helpText={`The error message shown if the customer hasn't selected a date and you've activated the "required" validation setting.`}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="messages.timeMissing"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Time missing"
                        placeholder="Please select a time"
                        helpText={`The error message shown if the customer hasn't selected a time and you've activated the "required" validation setting.`}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="messages.noTimes"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="No times"
                        placeholder="No times available for this date"
                        helpText={`The message shown inside the timeslot dropdown if no valid times are returned for the selected date.`}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="messages.chooseTime"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Choose time"
                        placeholder="-- Please choose a time --"
                        helpText={`The message shown inside the timeslot dropdown once new timeslots are loaded in but none have been selected.`}
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="messages.soldOut"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Sold out"
                        placeholder="(Sold Out)"
                        helpText={`This text will be added to the end of the timeslot if the order limit has been reached.`}
                        {...field}
                      />
                    )}
                  />
                </FormLayout>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <Text variant="headingMd" fontWeight="medium" as="h2">
                  Month Names
                </Text>
                <FormLayout>
                  <FormLayout.Group>
                    {monthNames.map((month, index) => (
                      <Controller
                        key={month}
                        name={`monthNames.${index}`}
                        control={control}
                        render={({ field }) => (
                          <TextField
                            autoComplete="off"
                            label={month}
                            placeholder={month}
                            {...field}
                          />
                        )}
                      />
                    ))}
                  </FormLayout.Group>
                </FormLayout>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <Text variant="headingMd" fontWeight="medium" as="h2">
                  Day Names (Short)
                </Text>
                <FormLayout>
                  <FormLayout.Group>
                    <Controller
                      name="dayNamesShort.0"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoComplete="off"
                          label="Sunday"
                          placeholder="Sun"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="dayNamesShort.1"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoComplete="off"
                          label="Monday"
                          placeholder="Mon"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="dayNamesShort.2"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoComplete="off"
                          label="Tuesday"
                          placeholder="Tue"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="dayNamesShort.3"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoComplete="off"
                          label="Wednesday"
                          placeholder="Wed"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="dayNamesShort.4"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoComplete="off"
                          label="Thursday"
                          placeholder="Thu"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="dayNamesShort.5"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoComplete="off"
                          label="Friday"
                          placeholder="Fri"
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="dayNamesShort.6"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          autoComplete="off"
                          label="Saturday"
                          placeholder="Sat"
                          {...field}
                        />
                      )}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <Text variant="headingMd" fontWeight="medium" as="h2">
                  Tag Labels
                </Text>
                <FormLayout>
                  <Controller
                    name="tags.date"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Date"
                        placeholder="Date"
                        helpText='The prefix used for the selected date Tag, e.g. "Delivery Date" = Delivery Date: 01/01/2023. Leave blank to show no prefix and just the selected value.'
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="tags.time"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Time"
                        placeholder="Time"
                        helpText='The prefix used for the selected time Tag, e.g. "Delivery Time" = Delivery Time: 9:00am - 10:00am. Leave blank to show no prefix and just the selected value.'
                        {...field}
                      />
                    )}
                  />
                  <Controller
                    name="tags.method"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        autoComplete="off"
                        label="Method"
                        placeholder="Method"
                        helpText='The prefix used for the selected method Tag, e.g. "Delivery Method" = Delivery Method: Pick Up. Leave blank to show no prefix and just the selected value.'
                        {...field}
                      />
                    )}
                  />
                </FormLayout>
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
      </FormProvider>
    </>
  )
}

export { LocalizationForm }
