import {
  BlockStack,
  Card,
  Layout,
  SkeletonBodyText,
  SkeletonDisplayText,
} from '@shopify/polaris'

import { SkeletonTable } from '@/components/shared/SkeletonTable'

const Skeleton = () => {}

type AnnotatedSectionProps = {
  children: React.ReactNode
  title: string
  skeletonSections: number[]
}

const AnnotatedSection = ({
  children,
  title,
  skeletonSections,
}: AnnotatedSectionProps) => {
  return (
    <Layout.AnnotatedSection title={title} description={children}>
      <BlockStack gap="400">
        {[...skeletonSections].map((numberOfFields, i) => (
          <Card key={`skeleton-annotated-section-${i}`}>
            <BlockStack gap="800">
              {[...Array(numberOfFields)].map((_, j) => (
                <BlockStack
                  gap="400"
                  key={`skeleton-annotated-section-${i}-field-${j}`}
                >
                  <SkeletonDisplayText size="small" />
                  <SkeletonBodyText lines={2} />
                </BlockStack>
              ))}
            </BlockStack>
          </Card>
        ))}
      </BlockStack>
    </Layout.AnnotatedSection>
  )
}

Skeleton.AnnotatedSection = AnnotatedSection
Skeleton.Table = SkeletonTable

export { Skeleton }
