import React from 'react'
import { Button, ButtonGroup, Icon } from '@shopify/polaris'
import { HelpText } from './HelpText'
import { DAYS } from '../../common/constants'
import { XSmallIcon, CheckSmallIcon } from '@shopify/polaris-icons'

interface DayPickerProps {
  helpText: string
  selectedDays: Array<number>
  onClick: (selectedDays: Array<number>) => void
}

const DayPicker: React.FC<DayPickerProps> = ({
  helpText,
  selectedDays,
  onClick,
}) => {
  const handleDayClick = (value: number) => {
    const newSelectedDays = selectedDays.includes(value)
      ? selectedDays.filter((day) => day !== value) // Remove the day if it's already selected
      : [...selectedDays, value].sort((a, b) => a - b) // Add the day and sort numerically

    onClick(newSelectedDays)
  }

  return (
    <div className="button-group__wrapper">
      <ButtonGroup fullWidth variant="segmented">
        {DAYS.map(({ label, value }) => {
          const isSelected = selectedDays.includes(value) || false
          return (
            <Button
              key={value}
              icon={
                isSelected ? (
                  <Icon source={() => <CheckSmallIcon />} />
                ) : (
                  <Icon source={() => <XSmallIcon />} />
                )
              }
              tone={isSelected ? 'success' : undefined}
              variant={isSelected ? 'primary' : undefined}
              onClick={() => handleDayClick(value)}
            >
              {label}
            </Button>
          )
        })}
      </ButtonGroup>
      {helpText !== '' && <HelpText>{helpText}</HelpText>}
    </div>
  )
}

export { DayPicker }
