import { useState } from 'react'
import { BlockStack, Box, Button, Link, Popover, Text } from '@shopify/polaris'

type Props = {
  customer: {
    firstName: string
    lastName: string
    id: string
    legacyResourceId: string
    email: string
    phone: string
    defaultAddress: {
      address1: string
      formattedArea: string
    }
  }
}

const CustomerPopover: React.FC<Props> = ({ customer }) => {
  const [popoverActive, setPopoverActive] = useState(false)

  const customerName =
    customer?.firstName || customer?.lastName
      ? `${customer?.firstName || ''} ${customer?.lastName || ''}`
      : customer?.email || ''

  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      {customer ? (
        <Popover
          active={popoverActive}
          preferredAlignment="left"
          preferredPosition="below"
          activator={
            <Button
              variant="monochromePlain"
              removeUnderline /* deprecated but no alternative? */
              disclosure
              onClick={() => {
                setPopoverActive(!popoverActive)
              }}
            >
              {customerName}
            </Button>
          }
          autofocusTarget="first-node"
          onClose={() => setPopoverActive(false)}
          fluidContent={true}
        >
          <Box padding="300" minWidth="240px">
            <BlockStack gap="200">
              <BlockStack gap="0">
                <Text as="span" variant="bodyMd" fontWeight="medium">
                  {customerName}
                </Text>
                {customer?.defaultAddress?.address1 && (
                  <>
                    <Text as="span" variant="bodyMd" fontWeight="regular">
                      {customer.defaultAddress?.address1}
                    </Text>
                    <Text as="span" variant="bodyMd" fontWeight="regular">
                      {customer.defaultAddress?.formattedArea}
                    </Text>
                  </>
                )}
              </BlockStack>
              <BlockStack gap="0">
                {customer?.email && (
                  <Link url={`mailto:${customer.email}`}>
                    <Text as="span" variant="bodyMd" fontWeight="regular">
                      {customer?.email}
                    </Text>
                  </Link>
                )}
                {customer?.phone && (
                  <Text as="span" variant="bodyMd" fontWeight="regular">
                    {customer.phone}
                  </Text>
                )}
              </BlockStack>
              <Button
                external
                //url={`shopify:admin/customers/${customer?.legacyResourceId}`}
                onClick={() => {
                  window.open(
                    `shopify:admin/customers/${customer?.legacyResourceId}`,
                    '_blank',
                    'noopener,noreferrer'
                  )
                  setPopoverActive(false)
                }}
              >
                View customer
              </Button>
            </BlockStack>
          </Box>
        </Popover>
      ) : null}
    </div>
  )
}

export { CustomerPopover }
