import { createFileRoute } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { overridesQueryOptions } from '@/common/queries'
import { OverrideForm } from '@/components/overrides/OverrideForm'
import { BlockStack, Layout, SkeletonPage, Text } from '@shopify/polaris'
import { Skeleton } from '@/components/shared/Skeleton'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/overrides')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      // Pre-fetch data
      queryClient.ensureQueryData(overridesQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const { data: overrides } = useSuspenseQuery(overridesQueryOptions())

  return (
    <PageFrame title="Product overrides">
      <OverrideForm overrides={overrides} />
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title="Product overrides">
      <Layout>
        <Skeleton.AnnotatedSection
          title="Override groups"
          skeletonSections={[3]}
        >
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Override groups allow you to override specific settings for a
              group of products.
            </Text>

            <Text variant="bodyMd" as="p">
              You can delete an override group by removing all existing products
              using the text button at the bottom of the group. Once the
              products have been removed, the option to delete the group will
              appear.
            </Text>
          </BlockStack>
        </Skeleton.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
