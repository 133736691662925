// types.ts
import { z } from 'zod'

export const schema = z.object({
  uuid: z.string().uuid(),
  shopId: z.string(),
  enabled: z.boolean(),
  name: z.string(),
  isDefault: z.boolean(),
  enabledDays: z.number().array(),
  disabledDates: z.string().array(),
  minDaysInAdvance: z.number().nullable(),
  maxDaysInAdvance: z.number().nullable(),
  timeEnabled: z.boolean(),
  timeSlots: z.array(
    z.object({
      allowSimultaneous: z.boolean(),
      days: z.array(z.number()),
      times: z.array(
        z.object({
          from: z.object({
            minutes: z.number(),
            time: z.string(),
          }),
          to: z.object({
            minutes: z.number(),
            time: z.string(),
          }),
          limit: z.number().nullable(),
        })
      ),
    })
  ),
  prepTime: z.number().nullable(),
  cutOffTime: z.number().nullable(),
  createdAt: z.string(),
  navIcon: z.string(),
  slug: z.string(),
  cutOffTimeType: z.enum(['SAME_DAY', 'NEXT_DAY']),
})

export type FormSchema = z.infer<typeof schema>
