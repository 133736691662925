// React, React Query, Context
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

import { useMutateInstall } from '@/hooks/useMutateInstall'

// Components
import { Transition } from '@/components/shared/Transition'
import { BlockStack, Link, Text } from '@shopify/polaris'
import {
  installStatusQueryOptions,
  settingsQueryOptions,
} from '@/common/queries'
import { buildAdminUrl } from '@/common/functions'

const AuthGuard = ({ children }: { children: JSX.Element }) => {
  const { mutate, isPending } = useMutateInstall()

  const { isLoading, isError, isSuccess, data } = useQuery(
    installStatusQueryOptions()
  )

  const { isLoading: isSettingsLoading, isFetched: isSettingsFetched } =
    useQuery({ ...settingsQueryOptions(), enabled: data?.status || isSuccess })

  const settingsUnresolved = isSettingsLoading || !isSettingsFetched

  useEffect(() => {
    if (data) {
      if (data.status === false) {
        mutate(undefined, {
          onSuccess: (data) => {
            const updatedUrl = buildAdminUrl(data)
            open(updatedUrl, '_top')
          },
        })
      }
    }
  }, [data])

  // While we're fetching, show the loading screen
  if (isLoading || settingsUnresolved) {
    return (
      <Transition
        title="Authenticating your subscription"
        content={
          <BlockStack gap="200" inlineAlign="center">
            <Text as="p" alignment="center">
              <br />
              Thank you for using Bloom!
              <br />
              Please contact us if you have any questions or get stuck during
              setup.
              <br />
              <br />
              You can visit the{' '}
              <Link
                target="_blank"
                url="https://www.shopside.com.au/docs/bloom"
              >
                Help Center
              </Link>{' '}
              for access to our documentation, or use our in-app support to send
              us a message. We always try to respond within 24 hours.
            </Text>
          </BlockStack>
        }
      />
    )
  }

  // If there was an error, show the error screen
  if (isError || !data) {
    return (
      <Transition
        title="Hmm, something went wrong."
        content={
          <BlockStack gap="200" inlineAlign="center">
            <Text as="p" alignment="center">
              This is an embedded app that needs to be loaded from within
              Shopify. Please try accessing it again via your Shopify admin.
            </Text>
            <Text as="p" alignment="center">
              If the issue persists, please get in contact with our support
              team.
            </Text>
          </BlockStack>
        }
      />
    )
  }

  if (isPending || data.status === false) {
    return (
      <Transition
        title="Setting up Bloom"
        content={
          <BlockStack gap="200" inlineAlign="center">
            <Text as="p" alignment="center">
              <br />
              Thank you for using Bloom!
              <br />
              Please contact us if you have any questions or get stuck during
              setup.
              <br />
              <br />
              You can visit the{' '}
              <Link
                target="_blank"
                url="https://www.shopside.com.au/docs/bloom"
              >
                Help Center
              </Link>{' '}
              for access to our documentation, or use our in-app support to send
              us a message. We always try to respond within 24 hours.
            </Text>
          </BlockStack>
        }
      />
    )
  }

  return children
}

export { AuthGuard }
