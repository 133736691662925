import { useI18n } from '@shopify/react-i18n'
import { useCallback, useState } from 'react'

import {
  IndexTable,
  Card,
  Link,
  Layout,
  IndexFilters,
  useSetIndexFiltersMode,
  BlockStack,
  Text,
  EmptySearchResult,
  InlineStack,
  Badge,
  Pagination,
  Box,
  Button,
  Tooltip,
  Icon,
  Popover,
  TextField,
} from '@shopify/polaris'
import type { TabProps } from '@shopify/polaris'
import { AlertTriangleIcon } from '@shopify/polaris-icons'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import { CustomerPopover } from '@/components/shared/CustomerPopover'
import { FulfillmentBadge } from '@/components/shared/FulfillmentBadge'

import { OrdersFilter } from './OrdersFilter'
import { useQuery, useSuspenseQuery } from '@tanstack/react-query'
import { ordersQueryOptions, settingsQueryOptions } from '@/common/queries'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

const OrdersTable = () => {
  const [i18n] = useI18n()
  const { data: settings } = useSuspenseQuery(settingsQueryOptions())
  const { methods, timezone } = settings

  const today = new Date()
  today.setHours(0, 0, 0, 0)

  // Convert global store state to local state
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState(0)
  const [from, setFrom] = useState(
    dayjs(today).tz(timezone, true).tz('UTC').format()
  )
  const [to, setTo] = useState(
    dayjs(today).add(14, 'days').tz(timezone, true).tz('UTC').format()
  )
  const [activeDateRange, setActiveDateRange] = useState(2)

  const [views] = useState([
    'All',
    ...methods.map((method) => method.name ?? ''),
  ])

  const [syncTimestamp, setSyncTimestamp] = useState(
    Math.floor(new Date().getTime() / 1000)
  )
  const [popoverActive, setPopoverActive] = useState(false)
  const [calendarURLCopied, setCalendarURLCopied] = useState(false)

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((popoverActive) => !popoverActive)
    setCalendarURLCopied(false)
  }, [])

  const tabs: TabProps[] = views.map((item, index) => ({
    content: item,
    index,
    onAction: () => {},
    id: `${item}-${index}`,
    isLocked: true,
  }))

  const changeTab = (tab: number) => {
    setPage(1)
    setTab(tab)
  }

  const { mode, setMode } = useSetIndexFiltersMode()

  const { data, refetch, isFetching } = useQuery(
    ordersQueryOptions({
      from,
      to,
      method: tab !== 0 ? views[tab] : null,
      page,
    })
  )

  const { hasNext = false, total = 0, data: orders = [] } = data ?? {}

  const pagination = {
    hasNext: hasNext,
    hasPrevious: page > 1,
    onNext: () => setPage(page + 1),
    onPrevious: () => setPage(page - 1),
  }

  const rowMarkup = orders.map(
    (
      {
        order: {
          uuid,
          name = '',
          deliveryMethod = '',
          deliveryDate = '',
          deliveryTime = '',
        },
        apiData: {
          customer,
          legacyResourceId,
          totalPriceSet,
          fullyPaid,
          displayFulfillmentStatus,
        },
      },
      index
    ) => {
      const priceLabel = totalPriceSet
        ? i18n.formatCurrency(totalPriceSet?.shopMoney?.amount, {
            currency: totalPriceSet?.shopMoney?.currencyCode ?? 'USD',
          })
        : ''

      const isFulfilled = displayFulfillmentStatus === 'FULFILLED'
      const hasError = !legacyResourceId

      return (
        <IndexTable.Row
          id={uuid}
          key={uuid}
          position={index}
          tone={hasError ? 'warning' : isFulfilled ? 'subdued' : undefined}
          disabled={hasError}
          onClick={() => {
            if (!hasError) {
              window.open(
                `shopify:admin/orders/${legacyResourceId}`,
                '_blank',
                'noopener,noreferrer'
              )
            }
          }}
        >
          <IndexTable.Cell>
            {legacyResourceId ? (
              <Link
                target="_blank"
                dataPrimaryLink
                url={`shopify:admin/orders/${legacyResourceId}`}
                removeUnderline
                monochrome
              >
                <Text variant="bodyMd" fontWeight="bold" as="span">
                  {name}
                </Text>
              </Link>
            ) : (
              <Tooltip
                padding="400"
                content="Bloom can't find this order in Shopify. It may be older than 60 days, or was deleted."
              >
                <InlineStack align="start" gap="200" wrap={false}>
                  <Box>
                    <Icon source={() => <AlertTriangleIcon />} />
                  </Box>
                  <Text variant="bodyMd" fontWeight="bold" as="span">
                    {name}
                  </Text>
                </InlineStack>
              </Tooltip>
            )}
          </IndexTable.Cell>
          <IndexTable.Cell>{deliveryDate}</IndexTable.Cell>
          <IndexTable.Cell>{deliveryTime}</IndexTable.Cell>
          <IndexTable.Cell>{deliveryMethod}</IndexTable.Cell>
          <IndexTable.Cell>
            <CustomerPopover customer={customer} />
          </IndexTable.Cell>
          <IndexTable.Cell>
            <Text as="span" alignment="end">
              {priceLabel}
            </Text>
          </IndexTable.Cell>
          <IndexTable.Cell>
            {fullyPaid ? (
              <Badge progress="complete">Paid</Badge>
            ) : fullyPaid === false ? (
              <Badge progress="partiallyComplete">Unpaid</Badge>
            ) : null}
          </IndexTable.Cell>
          <IndexTable.Cell>
            <FulfillmentBadge fulfillmentStatus={displayFulfillmentStatus} />
          </IndexTable.Cell>
        </IndexTable.Row>
      )
    }
  )

  const calendarURL = `${import.meta.env.VITE_APP_API_URL}/orders-feed?shop=${
    shopify.config.shop
  }&key=${settings.shopKey}&ts=${syncTimestamp}`

  const copyTextToClipboard = async (text: string) => {
    setCalendarURLCopied(true)
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text)
    } else {
      return document.execCommand('copy', true, text)
    }
  }

  return (
    <Layout.Section>
      <BlockStack gap="400">
        <InlineStack align="space-between">
          <InlineStack align="space-between" gap="200">
            <OrdersFilter
              timezone={timezone}
              to={to}
              from={from}
              activeDateRange={activeDateRange}
              setActiveDateRange={setActiveDateRange}
              setFilteredDates2={({ from, to }) => {
                console.log(from, to)
                setFrom(from)
                setTo(to)
              }}
            />
            <Button
              onClick={() => {
                refetch()
                setSyncTimestamp(Math.floor(new Date().getTime() / 1000))
              }}
            >
              Refresh
            </Button>
          </InlineStack>
          <Box>
            <Popover
              preferredAlignment="right"
              preferredPosition="below"
              active={popoverActive}
              activator={
                <Button onClick={togglePopoverActive} disclosure>
                  Sync with Google
                </Button>
              }
              onClose={togglePopoverActive}
              ariaHaspopup={false}
              sectioned
            >
              <Box paddingBlockEnd="100" paddingBlockStart="100">
                <BlockStack gap="200">
                  <Text as="p" fontWeight="medium">
                    Calendar URL
                  </Text>
                  <TextField
                    label="Calendar URL"
                    labelHidden
                    value={calendarURL}
                    helpText={
                      <Text as="p" variant="bodyMd">
                        Sync orders with your Google or Outlook calendar using
                        this URL. To learn how, or if orders aren't syncing,{' '}
                        <Link url="https://www.shopside.com.au/bloom-article/syncing-bloom-orders-with-google-calendar-or-outlook">
                          visit our help center
                        </Link>
                        .
                      </Text>
                    }
                    autoComplete="off"
                    readOnly
                    selectTextOnFocus
                    connectedRight={
                      <Button
                        size="large"
                        onClick={() => copyTextToClipboard(calendarURL)}
                      >
                        {calendarURLCopied ? 'Copied!' : 'Copy'}
                      </Button>
                    }
                  />
                </BlockStack>
              </Box>
            </Popover>
          </Box>
        </InlineStack>
        <Card padding="0">
          <IndexFilters
            selected={tab}
            onSelect={changeTab}
            queryValue=""
            queryPlaceholder="Searching in all"
            onQueryChange={() => {}}
            onQueryClear={() => {}}
            canCreateNewView={false}
            primaryAction={{
              type: 'cancel',
              onAction: async () => false,
              disabled: true,
              loading: false,
            }}
            cancelAction={{
              onAction: () => {},
              disabled: true,
              loading: false,
            }}
            filters={[]}
            appliedFilters={[]}
            onClearAll={() => {}}
            mode={mode}
            setMode={setMode}
            loading={isFetching}
            hideFilters
            hideQueryField
            tabs={tabs}
          />
          <IndexTable
            emptyState={
              <EmptySearchResult
                title="No orders match these filters"
                description="Try changing the date range in the top left corner, or adjusting your filters."
                withIllustration
              />
            }
            resourceName={{
              singular: 'order',
              plural: 'orders',
            }}
            itemCount={total}
            headings={[
              { title: 'Order' },
              { title: 'Date' },
              { title: 'Time' },
              { title: 'Method' },
              { title: 'Customer' },
              { title: 'Total', alignment: 'end' },
              { title: 'Payment status' },
              { title: 'Fulfillment status' },
            ]}
            selectable={false}
            hasMoreItems
          >
            {rowMarkup}
          </IndexTable>
          <Box background="bg-surface-secondary" padding="100">
            <Pagination type="table" {...pagination} />
          </Box>
        </Card>
      </BlockStack>
    </Layout.Section>
  )
}

export { OrdersTable }
