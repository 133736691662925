import { I18nContext, I18nManager } from '@shopify/react-i18n'
import { RouterProvider, createRouter } from '@tanstack/react-router'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { AppProvider as PolarisProvider } from '@shopify/polaris'
// Third party
import '@shopify/polaris/build/esm/styles.css'
import enTranslations from '@shopify/polaris/locales/en.json'

import './App.scss'

// Components
import { AuthGuard } from './components/shared/AuthGuard'

// Import the generated route tree
import { routeTree } from './routeTree.gen'

// Create a new QueryClient instance with default options
export const queryClient = new QueryClient()

// Create a new router instance
const router = createRouter({
  routeTree,
  context: {
    queryClient,
  },
  defaultPendingMs: 0,
  defaultPendingMinMs: 500,
  defaultErrorComponent: (_) => {
    //return <Error />
  },
  //defaultNotFoundComponent: () => <NotFound />,
  //defaultViewTransition: true,
})

// Get the locale from the Shopify config and create a new I18nManager instance
const i18nManager = new I18nManager({
  locale: shopify.config.locale,
})

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <I18nContext.Provider value={i18nManager}>
        <PolarisProvider i18n={enTranslations}>
          <AuthGuard>
            <RouterProvider router={router} />
          </AuthGuard>
        </PolarisProvider>
      </I18nContext.Provider>
    </QueryClientProvider>
  )
}

export { App }
