import {
  Banner,
  Button,
  ButtonGroup,
  Checkbox,
  FormLayout,
  Popover,
  Select,
  TextField,
  InlineStack,
  Card,
  BlockStack,
  Text,
  Box,
  Bleed,
  Icon,
  Tooltip,
} from '@shopify/polaris'
import { XSmallIcon } from '@shopify/polaris-icons'
import { useState } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { TimeSelect } from '@/components/shared/TimeSelect'
import { TIME_SELECT_OPTIONS, TIME_SLOT_TEMPLATE } from '@/common/constants'
import { generateTimeSlots } from '@/common/functions'
import { DayPicker } from '@/components/shared/DayPicker'
import { FormSchema } from './MethodForm/schema'
import { HelpText } from '../shared/HelpText'

export const TimeSlotGroup = ({
  timeSlotIndex,
  removeGroup,
}: {
  timeSlotIndex: number
  removeGroup: () => void
}) => {
  const { control } = useFormContext<FormSchema>()

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `timeSlots.${timeSlotIndex}.times`,
  })

  const [isTimeSlotGeneratorOpen, setIsTimeSlotGeneratorOpen] = useState(false)
  const [timeSlotGeneratorInterval, setTimeSlotGeneratorInterval] = useState(30)
  const [timeSlotGeneratorFrom, setTimeSlotGeneratorFrom] = useState(540)
  const [timeSlotGeneratorTo, setTimeSlotGeneratorTo] = useState(1020)
  const [timeSlotGeneratorLimit, setTimeSlotGeneratorLimit] = useState<
    string | null
  >(null)

  const addTime = () => {
    append({
      from: TIME_SLOT_TEMPLATE.from,
      to: TIME_SLOT_TEMPLATE.to,
      limit: null,
    })
  }

  return (
    <Card>
      <BlockStack gap="400">
        <BlockStack gap="200">
          <Text variant="headingSm" fontWeight="medium" as="h3">
            Days of the week
          </Text>
          <Controller
            name={`timeSlots.${timeSlotIndex}.days`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <DayPicker
                helpText="Choose the days you would like to activate for this time group."
                selectedDays={value}
                onClick={onChange}
              />
            )}
          />
        </BlockStack>

        <BlockStack gap="200">
          <Text variant="headingSm" fontWeight="medium" as="h3">
            Time slot rules
          </Text>
          <Controller
            name={`timeSlots.${timeSlotIndex}.allowSimultaneous`}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Checkbox
                label="Allow orders to be placed up until the end of a timeslot"
                checked={value}
                onChange={onChange}
              />
            )}
          />
          <HelpText>
            <span>
              Activating this setting will allow customers to order up until{' '}
              <strong>12pm</strong> for a <strong>9am-12pm</strong> timeslot
              (for same-day orders). Otherwise, that timeslot will become
              unavailable at 9am.
            </span>
          </HelpText>
        </BlockStack>

        <BlockStack gap="200">
          <Text variant="headingSm" fontWeight="medium" as="h3">
            Time slots
          </Text>
          {fields.length <= 0 ? (
            <Banner
              title="There are no time slots for this group"
              tone="warning"
            >
              <p>
                Use the buttons below to generate a group of time slots, or add
                them individually.
              </p>
            </Banner>
          ) : (
            <>
              {fields.map((field, index) => (
                <div key={field.id}>
                  <InlineStack
                    wrap={false}
                    blockAlign="end"
                    gap={{ xs: '200' }}
                  >
                    <Box width="100%">
                      <FormLayout>
                        <FormLayout.Group condensed>
                          <Controller
                            name={`timeSlots.${timeSlotIndex}.times.${index}.from`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TimeSelect
                                label="From"
                                stringKey="from"
                                value={value.minutes.toString()}
                                onChange={(_, val) => onChange(val)}
                              />
                            )}
                          />
                          <Controller
                            name={`timeSlots.${timeSlotIndex}.times.${index}.to`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TimeSelect
                                label="To"
                                stringKey="to"
                                value={value.minutes.toString()}
                                onChange={(_, val) => onChange(val)}
                              />
                            )}
                          />
                          <Controller
                            name={`timeSlots.${timeSlotIndex}.times.${index}.limit`}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <TextField
                                label="Order limit"
                                type="number"
                                min={0}
                                value={value?.toString() || ''}
                                onChange={(val) =>
                                  onChange(val === '' ? null : parseInt(val))
                                }
                                autoComplete="off"
                              />
                            )}
                          />
                        </FormLayout.Group>
                      </FormLayout>
                    </Box>
                    <Tooltip content="Delete">
                      <Button
                        fullWidth
                        size="large"
                        onClick={() => remove(index)}
                        icon={<Icon source={() => <XSmallIcon />} />}
                        variant="primary"
                        tone="critical"
                      />
                    </Tooltip>
                  </InlineStack>
                </div>
              ))}
            </>
          )}
        </BlockStack>

        <Bleed marginInline="400" marginBlockEnd="400">
          <Box background="bg-surface-secondary" padding="400">
            <InlineStack align="space-between" blockAlign="center">
              {fields.length > 0 ? (
                <Button
                  variant="plain"
                  tone="critical"
                  onClick={() => replace([])}
                >
                  Clear time slots
                </Button>
              ) : (
                <Button variant="plain" tone="critical" onClick={removeGroup}>
                  Delete time group
                </Button>
              )}

              <ButtonGroup>
                <Popover
                  active={isTimeSlotGeneratorOpen}
                  activator={
                    <Button
                      onClick={() => {
                        setIsTimeSlotGeneratorOpen(true)
                      }}
                    >
                      Generate time slots
                    </Button>
                  }
                  onClose={() => {
                    setIsTimeSlotGeneratorOpen(false)
                  }}
                  ariaHaspopup={false}
                  sectioned
                >
                  <FormLayout>
                    <Select
                      label="Every"
                      labelInline
                      options={[
                        { label: '15 minutes', value: '15' },
                        { label: '30 minutes', value: '30' },
                        { label: '1 hour', value: '60' },
                        { label: '2 hours', value: '120' },
                      ]}
                      onChange={(val) => {
                        setTimeSlotGeneratorInterval(parseInt(val))
                      }}
                      value={timeSlotGeneratorInterval.toString()}
                    />
                    <Select
                      label="From"
                      labelInline
                      options={TIME_SELECT_OPTIONS}
                      onChange={(val) => {
                        setTimeSlotGeneratorFrom(parseInt(val))
                      }}
                      value={timeSlotGeneratorFrom.toString()}
                    />
                    <Select
                      label="To"
                      labelInline
                      options={TIME_SELECT_OPTIONS}
                      onChange={(val) => {
                        setTimeSlotGeneratorTo(parseInt(val))
                      }}
                      value={timeSlotGeneratorTo.toString()}
                    />
                    <TextField
                      label=""
                      prefix="Order limit"
                      type="number"
                      value={timeSlotGeneratorLimit?.toString() || ''}
                      onChange={setTimeSlotGeneratorLimit}
                      autoComplete="off"
                    />
                    <Button
                      variant="primary"
                      fullWidth
                      onClick={() => {
                        const timeSlots = generateTimeSlots(
                          timeSlotGeneratorFrom,
                          timeSlotGeneratorTo,
                          timeSlotGeneratorInterval,
                          timeSlotGeneratorLimit
                            ? parseInt(timeSlotGeneratorLimit)
                            : null
                        )

                        console.log('Timeslots', timeSlots)

                        // Add generated time slots to the form
                        timeSlots.forEach((slot) => {
                          append({
                            from: {
                              minutes: slot.from.minutes,
                              time: slot.from.time,
                            },
                            to: {
                              minutes: slot.to.minutes,
                              time: slot.to.time,
                            },
                            limit: slot.limit,
                          })
                        })
                        setIsTimeSlotGeneratorOpen(false)
                      }}
                    >
                      Generate
                    </Button>
                  </FormLayout>
                </Popover>
                <Button onClick={addTime}>Add time slot</Button>
              </ButtonGroup>
            </InlineStack>
          </Box>
        </Bleed>
      </BlockStack>
    </Card>
  )
}
