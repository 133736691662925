import { z } from 'zod'

const schema = z.object({
  fieldLabels: z.object({
    deliveryMethod: z.string(),
    deliveryDate: z.string(),
    deliveryTime: z.string(),
  }),
  messages: z.object({
    methodMissing: z.string(),
    dateMissing: z.string(),
    timeMissing: z.string(),
    noTimes: z.string(),
    chooseTime: z.string(),
    soldOut: z.string(),
  }),
  monthNames: z.record(z.string(), z.string()),
  dayNamesShort: z.record(z.string(), z.string()),
  tags: z.object({
    date: z.string(),
    time: z.string(),
    method: z.string(),
  }),
})

export { schema }
export type FormSchema = z.infer<typeof schema>
