import { useCallback } from 'react'
import { useForm } from 'react-hook-form'

import useMutateSettings from '@/hooks/useMutateSettings'
import useScrollToError from '@/hooks/useScrollToError'

import { Settings } from '@/common/types'

import { schema, type FormSchema } from './schema'
import formResolver from './validation'

export const useLocalizationForm = (settings: Settings) => {
  const form = useForm<FormSchema>({
    defaultValues: schema.parse(settings.localisation),
    resolver: formResolver(schema),
    mode: 'onChange',
    shouldFocusError: true,
  })

  const mutation = useMutateSettings()
  const [setCanFocus] = useScrollToError(form.formState.errors)

  const submit = useCallback(
    async (data: FormSchema) => {
      try {
        await mutation.mutateAsync({
          localisation: data,
        })
        form.reset(data)
        shopify.toast.show('Localization updated', { duration: 2000 })
      } catch {
        setCanFocus(true)
      }
      return true
    },
    [mutation, form, setCanFocus]
  )

  return {
    form,
    submit,
    isPending: mutation.isPending,
  }
}
