import { Badge, BadgeProgressValue } from '@shopify/polaris'

export type displayFulfillmentStatus =
  | 'FULFILLED'
  | 'PARTIALLY_FULFILLED'
  | 'UNFULFILLED'
  | 'IN_PROGRESS'
  | 'ON_HOLD'
  | 'SCHEDULED'

type Props = {
  fulfillmentStatus: displayFulfillmentStatus
}

const FulfillmentBadge: React.FC<Props> = ({ fulfillmentStatus }) => {
  let progress: BadgeProgressValue
  let label: string
  let tone: 'critical' | 'success' | 'warning' | 'attention' | undefined

  switch (fulfillmentStatus) {
    case 'FULFILLED':
      progress = BadgeProgressValue.Complete
      label = 'Fulfilled'
      tone = undefined
      break
    case 'UNFULFILLED':
      progress = BadgeProgressValue.Incomplete
      label = 'Unfulfilled'
      tone = 'attention'
      break
    case 'PARTIALLY_FULFILLED':
      progress = BadgeProgressValue.PartiallyComplete
      label = 'Partially fulfilled'
      tone = 'attention'
      break
    case 'IN_PROGRESS':
      progress = BadgeProgressValue.PartiallyComplete
      label = 'In progress'
      tone = 'attention'
      break
    case 'ON_HOLD':
      progress = BadgeProgressValue.Incomplete
      label = 'On hold'
      tone = 'warning'
      break
    case 'SCHEDULED':
      progress = BadgeProgressValue.Incomplete
      label = 'Scheduled'
      tone = 'attention'
      break
    default:
      progress = BadgeProgressValue.PartiallyComplete
      label = ''
      tone = undefined
  }

  return label ? (
    <Badge progress={progress} tone={tone}>
      {label}
    </Badge>
  ) : null
}

export { FulfillmentBadge }
