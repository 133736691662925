// Polaris library
import {
  Banner,
  BlockStack,
  Box,
  Button,
  InlineStack,
  ResourceItem,
  ResourceList,
  Text,
} from '@shopify/polaris'

import { Product } from '../../common/types'

type Props = {
  deleteButton: React.FC
  products: Product[]
  updateProducts: (products: Product[]) => void
}

export const ProductList = ({
  deleteButton: DeleteButton,
  products,
  updateProducts,
}: Props) => {
  const handleRemoveProduct = (id: string) => {
    updateProducts(products.filter((product) => product.id !== id))
  }

  const handleClearProducts = () => {
    updateProducts([])
  }

  const renderItem = (item: Product) => {
    const { id, title } = item

    return (
      <ResourceItem
        id={id}
        onClick={() => {
          // Required prop. Nothing to do here
        }}
        shortcutActions={[
          {
            content: 'Remove',
            onAction: () => handleRemoveProduct(id),
          },
        ]}
        persistActions
        accessibilityLabel={`View details for ${title}`}
      >
        <Text variant="headingSm" fontWeight="semibold" as="p">
          {title}
        </Text>
      </ResourceItem>
    )
  }

  const openResourcePicker = () => {
    shopify
      .resourcePicker({
        type: 'product',
        action: 'select',
        multiple: true,
      })
      .then((selection) => {
        if (!selection) return

        const selectedProducts = selection.map((product) => {
          return {
            id: product.id,
            title: product.title,
          }
        })

        updateProducts(selectedProducts)
      })
      .catch((error) => console.error(error))
  }

  return (
    <BlockStack gap="200">
      <BlockStack gap="200">
        <InlineStack align="space-between">
          <Text variant="headingSm" fontWeight="medium" as="h3">
            Products
          </Text>
          <Button variant="plain" onClick={openResourcePicker}>
            Add product
          </Button>
        </InlineStack>
        <ResourceList
          resourceName={{
            singular: 'product',
            plural: 'products',
          }}
          items={products}
          renderItem={renderItem}
          emptyState={
            <Banner title="No products added" tone="warning">
              <p>Add at least one product for the override to take effect.</p>
            </Banner>
          }
        />
      </BlockStack>

      <Box>
        {products.length > 0 ? (
          <Button variant="plain" tone="critical" onClick={handleClearProducts}>
            Remove all products
          </Button>
        ) : (
          <DeleteButton />
        )}
      </Box>
    </BlockStack>
  )
}
