// External Imports
import { FieldErrors, FormProvider } from 'react-hook-form'
import { SaveBar } from '@shopify/app-bridge-react'

// Custom Hooks
import useScrollToError from '@/hooks/useScrollToError'

// Form Imports
import { FormSchema } from './schema'
import { useDisplayForm } from './hooks'

// Component Imports
import { PositionSettings } from '@/components/display/PositionSettings'
import { DesignSettings } from '@/components/display/DesignSettings'
import { AdvancedSettings } from '@/components/display/AdvancedSettings'

import { Settings } from '@/common/types'

interface DisplayFormProps {
  settings: Settings
}

const DisplayForm = ({ settings }: DisplayFormProps) => {
  const { submit, isPending, form } = useDisplayForm(settings)

  const {
    formState: { isDirty },
    handleSubmit,
    reset,
  } = form

  const [setCanFocus] = useScrollToError(form.formState.errors)

  const onFormSubmitValid = (data: FormSchema) => {
    submit(data)
  }

  const onFormSubmitInvalid = (errors: FieldErrors<FormSchema>) => {
    setCanFocus(true)
    console.log(errors)
  }

  return (
    <>
      <SaveBar id="display-save-bar" open={isDirty}>
        <button
          variant="primary"
          onClick={() => handleSubmit(onFormSubmitValid, onFormSubmitInvalid)()}
          disabled={isPending}
          loading={isPending ? '' : undefined}
        ></button>
        <button onClick={() => reset()} disabled={isPending}></button>
      </SaveBar>
      <FormProvider {...form}>
        <PositionSettings />
        <DesignSettings />
        <AdvancedSettings />
      </FormProvider>
    </>
  )
}

export { DisplayForm }
