import { Controller, useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

import {
  BlockStack,
  Card,
  FormLayout,
  Icon,
  InlineError,
  Layout,
  Select,
  Text,
  TextField,
} from '@shopify/polaris'

import { ArrowLeftIcon, ArrowRightIcon } from '@shopify/polaris-icons'

import { Calendar } from 'react-calendar'

import { DayPicker } from '@/components/shared/DayPicker'

import { TIME_SELECT_OPTIONS } from '@/common/constants'
import { convertTimeToYYYYDDMM } from '@/common/functions'

const MethodDateSettings = () => {
  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext()

  const enabledDays = watch('enabledDays')

  return (
    <>
      <Layout.AnnotatedSection
        id="dateSettings"
        title="Date settings"
        description={
          <BlockStack gap="200">
            <Text variant="bodyMd" as="p">
              Select which days you would like to activate. These will be
              available for customers to select.
            </Text>
            <Text variant="bodyMd" as="p">
              Block out specific dates you don&apos;t want customers to be able
              to choose.
            </Text>
            <Text variant="bodyMd" as="p">
              Use the minimum and maximum cut-off days to limit the date range
              from which your customers can select.
            </Text>
            <Text variant="bodyMd" as="p">
              Set a cut-off time for same-day or next-day orders, to ensure you
              have enough time to prepare their order.
            </Text>
          </BlockStack>
        }
      >
        <BlockStack gap="400">
          <Card>
            <BlockStack gap="400">
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="medium" as="h3">
                  Active Days
                </Text>
                <Controller
                  name="enabledDays"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <DayPicker
                      helpText="Choose the days you would like to activate in your calendar for this delivery method."
                      selectedDays={value}
                      onClick={onChange}
                    />
                  )}
                />
              </BlockStack>
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="medium" as="h3">
                  Blockout Dates
                </Text>
                <Controller
                  name="disabledDates"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Calendar
                      minDate={new Date()}
                      minDetail="month"
                      showNeighboringMonth={false}
                      prev2Label={null}
                      next2Label={null}
                      tileClassName={({ date }) => {
                        const today = new Date()
                        today.setHours(0, 0, 0, 0)

                        if (date < today) {
                          return 'react-calendar__tile--disabled'
                        }

                        let status = 'unblocked'

                        if (value.includes(convertTimeToYYYYDDMM(date))) {
                          status = 'blocked'
                        }

                        // If the day is disabled, or the specific date is disabled, add the disabled class
                        if (!enabledDays.includes(date.getDay())) {
                          status = 'disabled'
                        }

                        return `react-calendar__tile react-calendar__tile--${status}`
                      }}
                      onChange={(date: Date) => {
                        // If the day is already disabled, don't add the date to the array
                        if (!enabledDays.includes(date.getDay())) {
                          return
                        }

                        const dateValue = convertTimeToYYYYDDMM(date)

                        // Current disabled, toggle to enabled
                        const indexInArray = value.indexOf(dateValue)

                        const updatedDisabledDates = [...value]

                        if (indexInArray === -1) {
                          updatedDisabledDates.push(dateValue)
                        } else {
                          updatedDisabledDates.splice(indexInArray, 1)
                        }

                        onChange(updatedDisabledDates)
                      }}
                      prevLabel={<Icon source={() => <ArrowLeftIcon />} />}
                      nextLabel={<Icon source={() => <ArrowRightIcon />} />}
                    />
                  )}
                />
              </BlockStack>
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="medium" as="h3">
                  Days in Advance
                </Text>
                <FormLayout>
                  <FormLayout.Group>
                    <Controller
                      name="minDaysInAdvance"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Minimum days"
                          helpText="The smallest number of days in advance your customers can order."
                          type="number"
                          min={0}
                          value={value?.toString() || ''}
                          onChange={(val) =>
                            onChange(val === '' ? null : parseInt(val))
                          }
                          autoComplete="off"
                        />
                      )}
                    />
                    <Controller
                      name="maxDaysInAdvance"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          label="Maximum days"
                          helpText="The largest number of days in advance can your customers order."
                          type="number"
                          min={0}
                          value={value?.toString() || ''}
                          onChange={(val) =>
                            onChange(val === '' ? null : parseInt(val))
                          }
                          autoComplete="off"
                        />
                      )}
                    />
                  </FormLayout.Group>
                  <ErrorMessage
                    errors={errors}
                    name="minDaysInAdvance"
                    render={({ message }) => (
                      <div id="sellingPlans:Error">
                        <InlineError
                          message={message}
                          fieldID="sellingPlans:Error"
                        />
                      </div>
                    )}
                  />
                </FormLayout>
              </BlockStack>
              <BlockStack gap="200">
                <Text variant="headingSm" fontWeight="medium" as="h3">
                  Cut off time
                </Text>
                <FormLayout>
                  <FormLayout.Group>
                    <Controller
                      name="cutOffTime"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label="Order cut-off time"
                          helpText="The time you stop accepting new orders."
                          options={[
                            { value: '', label: 'No cut off time' },
                            ...TIME_SELECT_OPTIONS.slice(1),
                          ]}
                          onChange={(val) => {
                            onChange(val === '' ? null : parseInt(val, 10))
                          }}
                          value={value?.toString() || ''}
                        />
                      )}
                    />
                    <Controller
                      name="cutOffTimeType"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Select
                          label="Same day or next day"
                          helpText="If the cut off time is applied to the current day or the next day."
                          options={[
                            { value: 'SAME_DAY', label: 'Same day' },
                            { value: 'NEXT_DAY', label: 'Next day' },
                          ]}
                          onChange={onChange}
                          value={value?.toString() || 'SAME_DAY'}
                        />
                      )}
                    />
                  </FormLayout.Group>
                </FormLayout>
              </BlockStack>
            </BlockStack>
          </Card>
        </BlockStack>
      </Layout.AnnotatedSection>
    </>
  )
}

export { MethodDateSettings }
