import { useState, useCallback } from 'react'

import {
  Button,
  Popover,
  DatePicker,
  InlineStack,
  Box,
  OptionList,
  InlineGrid,
  useBreakpoints,
} from '@shopify/polaris'

import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)

type Props = {
  to: string
  from: string
  timezone: string
  activeDateRange: number
  setFilteredDates2: (dates: { from: string; to: string }) => void
  setActiveDateRange: (activeDateRange: number) => void
}

const today = new Date(new Date().setHours(0, 0, 0, 0))
const tomorrow = new Date(
  new Date(new Date().setDate(today.getDate() + 1)).setHours(0, 0, 0, 0)
)
const thirtyDaysAgo = new Date(
  new Date(new Date().setDate(today.getDate() - 30)).setHours(0, 0, 0, 0)
)

const OrdersFilter = ({
  to,
  from,
  timezone,
  activeDateRange,
  setActiveDateRange,
  setFilteredDates2,
}: Props) => {
  const { mdDown } = useBreakpoints()
  const [popoverActive, setPopoverActive] = useState(false)

  const [filteredDates, setFilteredDates] = useState({
    from,
    to,
  })

  const [{ month, year }, setDate] = useState({
    month: today.getMonth(),
    year: today.getFullYear(),
  })

  const ranges = [
    {
      title: 'Today',
      button: 'Today',
      alias: 'today',
      period: {
        from: today,
        to: today,
      },
    },
    {
      title: 'Tomorrow',
      button: 'Tomorrow',
      alias: 'tomorrow',
      period: {
        from: tomorrow,
        to: tomorrow,
      },
    },
    {
      title: 'Custom',
      button:
        from !== to
          ? `${dayjs(from).format('DD MMM')} - ${dayjs(to).format('DD MMM')}`
          : `${dayjs(from).format('DD MMM')}`,
      alias: 'custom',
      period: {
        from: new Date(new Date(filteredDates.from).setHours(0, 0, 0, 0)),
        to: new Date(new Date(filteredDates.to).setHours(0, 0, 0, 0)),
      },
    },
  ]

  const handleMonthChange = useCallback(
    (month: number, year: number) => setDate({ month, year }),
    []
  )

  const togglePopoverActive = useCallback(() => {
    setPopoverActive((popoverActive) => !popoverActive)
  }, [])

  return (
    <Popover
      active={popoverActive}
      activator={
        <Button onClick={togglePopoverActive} disclosure>
          {ranges[activeDateRange].button}
        </Button>
      }
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      preferredAlignment="left"
      fluidContent
      sectioned
    >
      <Popover.Pane fixed>
        <InlineGrid
          columns={{
            sm: '1fr',
            md: 'max-content max-content',
          }}
          gap="0"
        >
          <Box
            maxWidth={mdDown ? '516px' : '212px'}
            width={mdDown ? '100%' : '212px'}
            padding="200"
          >
            <OptionList
              options={ranges.map((range) => ({
                value: range.alias,
                label: range.title,
              }))}
              selected={[ranges[activeDateRange].alias]}
              onChange={(value) => {
                const range = ranges.findIndex(
                  (range) => range.alias === value[0]
                )
                setFilteredDates({
                  from: dayjs(ranges[range]?.period.from)
                    .tz(timezone, true)
                    .tz('UTC')
                    .format(),
                  to: dayjs(ranges[range]?.period.to)
                    .tz(timezone, true)
                    .tz('UTC')
                    .format(),
                })
                setActiveDateRange(range)
              }}
            />
          </Box>
          <Box padding="400" maxWidth={'320px'}>
            <DatePicker
              month={month}
              year={year}
              disableDatesBefore={thirtyDaysAgo}
              onChange={(value) => {
                setFilteredDates({
                  from: dayjs(value.start)
                    .tz(timezone, true)
                    .tz('UTC')
                    .format(),
                  to: dayjs(value.end).tz(timezone, true).tz('UTC').format(),
                })
                setActiveDateRange(2)
              }}
              onMonthChange={handleMonthChange}
              selected={{
                start: ranges[activeDateRange].period.from,
                end: ranges[activeDateRange].period.to,
              }}
              allowRange
            />
            <Box paddingBlockStart="200">
              <InlineStack align="end">
                <Button
                  variant="primary"
                  onClick={() => {
                    setFilteredDates2({
                      from: filteredDates.from,
                      to: filteredDates.to,
                    })

                    togglePopoverActive()
                  }}
                >
                  Apply
                </Button>
              </InlineStack>
            </Box>
          </Box>
        </InlineGrid>
      </Popover.Pane>
    </Popover>
  )
}

export { OrdersFilter }
